.left_tab_menu {
    border-bottom: none;
    border-right: 1px solid rgba(63, 39, 131, 0.1);
    padding-top: 20px;
    min-height: 605px;
    height: 100%;
    overflow: hidden;
}

.left_tab_menu * {
    /* Apply ellipsis to child elements */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; /* Ensure child elements take up full width */
}
.nav_item .nav_link{
    color: #828282;
    background-color: #fff;
    padding: 10px 6px 10px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 75%; */
}
.tab_content {
    padding-top: 20px;
}
.content_main_title{
    color: #3f2783;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;
}
hr {
    margin-top: 20px;
    border-top: 1px solid rgb(63 39 131);
    width: 100%;
    margin-bottom: 20px;
}
