.height-100 {
    height: 100vh
}

.card {
    width: 488px;
    border: none;
    /* height: 336px; */
    /* box-shadow: 0px 5px 20px 0px #d2dae3; */
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
}
.card span {
    line-height: 25px;
}
.card h6 {
    color:#000000;
    font-size: 20px
}

.inputs input {
    width: 40px;
    height: 40px;
    width: 40px;
    height: 40px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}

.card-2 {
    background-color: #fff;
    padding: 10px;
    width: 350px;
    height: 100px;
    bottom: -50px;
    left: 20px;
    position: absolute;
    border-radius: 5px
}

.card-2 .content {
    margin-top: 50px
}

.card-2 .content a {
    color: red
}

.form-control:focus {
    box-shadow: none;
    border: 2px solid red
}

.validate {
    border-radius: 20px;
    height: 40px;
    background-color: red;
    border: 1px solid red;
    width: 140px
}
.cross_btn {
    background-color: #65529c;
    color: #fff;
    float: right;
    display: block;
    padding: 2px 5px 2px 5px;
    margin-bottom: 31px;
    margin-left: auto;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
}