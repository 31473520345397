.inner_breadcrmb {
    margin-bottom: 40px;
}
ul.breadcrumb {
    background: transparent;
    padding: 0;
    margin: 0;
    border-radius: 0;
    line-height: 18px;
    display: flex;
}
ul.breadcrumb li::before {
    content: '>';
    position: absolute;
    top: 1px;
    right: -10px;
    color: #3f2783;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    line-height: 18px;
}
ul.breadcrumb li.active::before {
    content: none;
}
ul.breadcrumb li {
    margin-right: 14px;
    list-style-type: none;
    position: relative;
    color: #3f2783;

}
ul.breadcrumb li a {
    color: #3f2783;
    font-size: 14px;
    text-decoration: none;
}
ul.breadcrumb li.active {
    text-decoration: underline;
    color: #3f2783;
}
.form_loginuser {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 26px 0 0;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}
.user_image {
    border: 2px solid #3F2783;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
}
.user_image img{
    max-width: 100%;
}