.pm_diag_sec {
    margin-bottom: 20px;
    margin-top: 20px;
}
.pm_diag_sec .form_label {
    display: block;
    margin-bottom: 25px;
}
.pm_diag_sec .list_box_col{
    display: inline-block;
    vertical-align: top;
    width: 30%;
    padding-right: 10px;
    margin-bottom: 20px;
}
.arthro_prinary_diag_sec .pm_diag_sec .list_box_col{
    margin-bottom: 6px;
}

.search-box {
    border: 1px solid #3f2783b3;
    border-radius: 20px;
    height: 40px;
    margin: inherit;
    margin-top: 16px;
    position: relative;
    text-align: center;
    width: 287px;
}