.action_small_dev .dropdown_button::after {
    display: none !important;
}
.action_small_dev .dropdown_button {
    color: #3f2783;
}
.action_large_dev {
    display: block;
}

.action_large_dev ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.action_large_dev ul li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.action_arrow {
    margin-left: 10px;
}
.drop_down {}


@media only screen and (max-width: 1199px) {
    .action_small_dev {
        display: block;

    }
    .action_large_dev {
        display: none ;
    }
} 
@media only screen and (min-width: 1200px) {
   
    .action_small_dev {
        display: none;
    }
} 

