.delete_modal_main .modal-dialog{
    width: 400px;
}
.delete_modal_main .delete_pp_icon {
    margin-top: 20px;
    width: 24px;
}
.delete_modal_main .center_title {
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
    font-weight: normal;
    margin-bottom: 0;
}
.delete_modal_main .modal-footer {
    justify-content: center;
}
.delete_modal_main .btn_outline {
    width: 140px;
    margin-right: 15px;
}
.delete_modal_main .btn_danger {
    width: 140px;
}
.delete_modal_main .btn_green {
    width: 140px;
}
.btn_success {
    width: 140px;
    height: 40px;
    color: #fff;
    background: #0b780e;
    border-radius: 10px;
    border: 2px solid #0b780e;
    display: inline-block;
}
.btn_fill_1 {
    width: 140px;
    height: 40px;
    color: #fff;
    background: #3f2783;
    border-radius: 10px;
    border: 2px solid #3f2783;
    display: inline-block;
}