.add_white_icon, .saved_white_icon {
    display: none;
}
.cr_sn_btn, .view_sn_btn {
    width: 200px;
    font-weight: 600;
    margin-right: 15px;
    padding: 0 18px;
}
.cr_sn_btn {
    width: 200px;
}
.view_sn_btn {
    width: 160px;
}
.cr_sn_btn .add_icon{
    margin-right: 8px;
}
.cr_sn_btn .add_white_icon{
    margin-right: 8px;
}
.cr_sn_btn:hover .add_icon{
    display:none;
}
.cr_sn_btn:hover .add_white_icon{
    display:inline-block;
}
.view_sn_btn:hover .saved_icon{
    display: none;
}
.view_sn_btn:hover .saved_white_icon {
    display: inline-block;
}
.cr_sn_btn img, .view_sn_btn img {
    cursor: pointer;
    margin-top: -2px;
    width: 20px;
    margin-right: 6px;
}
.back_arr_txt {
    margin-bottom: 0;
    color: #3f2783;
    padding-top: 2px;
}
.saveedNote_rightPop {
    display: block;
    position: absolute;
    z-index: 1;
    top: 162px;
    right: 36px;
    color: #000;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 1s;
    background: #ffffff;
    border: 2px solid #3f2783;
    box-sizing: border-box;
    box-shadow: 0px 5px 20px 1px rgb(0 0 0 / 15%);
    border-radius: 20px;
    max-height: 450px;
    width: 500px;
}
.saveedNote_rightPop .saveedNote_inner_right {
    padding: 23px;
    width: 500px;
}
.right_menu_add_image {
    cursor: pointer;
}
.list_popup_header h4 {
    font-size: 18px;
    font-weight: 600;
}
.sort_view_main {
    margin-top: 15px;
}
.sort_view_main span {
    font-size: 12px;
    color: #3f2783;
    display: inline-block;
    width: 48%;
    cursor: pointer;
}
.sort_view_main span img {
    margin-left: 5px;
    margin-top: -2px;
}
.list_popup_content {
    width: 99%;
}
.saved_list_single {
    border: 1px solid rgba(63, 39, 131, 0.7);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 20px 8px 20px;
    padding-right: 4px;
    margin-bottom: 20px;
    padding-top: 0;
}
h3.single_note_title {
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
}
.single_note_sub_title {
    font-size: 12px;
    margin-bottom: 10px;
}
.single_note_right_icons img {
    width: 17px;
    margin: 0 8px;
    cursor: pointer;
}
.single_note_txt {
    font-size: 12px;
    color: #000;
}
.popup_content_scroll {
    margin-top: 40px;
    height: auto;
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
}
.view_all_note .popup_content_scroll{
    max-height: 680px;
}
.single_list_bottom p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    margin-right: 90px;

}
.list_popup_footer {
    padding: 25px 5px;
}
.list_popup_footer p {
    color: #3f2783;
    font-size: 14px;
    text-align: center;
}
.list_popup_footer p img {
    margin-right: 10px;
}
.view_all_note .sort_view_main {
    padding-right: 10px;
}


.saveedNote_rightPopaddnote {
    display: block;
    position: absolute;
    z-index: 1;
    top: 920px;
    right: 36px;
    color: #000;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 1s;
    background: #ffffff;
    border: 2px solid #3f2783;
    box-sizing: border-box;
    box-shadow: 0px 5px 20px 1px rgb(0 0 0 / 15%);
    border-radius: 20px;
    max-height: 450px;
    width: 500px;
}
.saveedNote_rightPopaddnote .saveedNote_inner_right {
    padding: 23px;
    width: 500px;
}