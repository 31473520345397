body {
  font-size: 14px;
}

label.user-letter {
  width: 40px;
  height: 40px;
  margin: 0;
  background: #5e60ce;
  border-radius: 50%;
  line-height: 36px;
  border: 2px solid #3f2783;
  text-align: center;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
}


/* line.line-12 {
    display: none;
} */

span.white_bg {
  position: absolute;
  width: 100%;
  right: 0px;
  height: 24px;
  background-color: white;
  bottom: 39px;
}

span.xlsx_bg {
  position: absolute;
  width: 100%;
  right: 0px;
  height: 24px;
  background-color: white;
  bottom: 39px;
}

.proms_height {
  height: 240px !important;
  min-height: 240px;
}

label.user-letter.large {
  width: 93px;
  height: 90px;
  line-height: 88px;
  font-size: 44px;
  border: 1.8px solid #3f2783;
}

label.user-letter.small {
  width: 28px;
  height: 28px;
  line-height: 26px;
  font-size: 18px;
  position: relative;
  z-index: 1;
}

.profile_pic_letter {
  font-size: 44px;
  color: #fff;
  background: linear-gradient(0deg, #5e60ce, #5e60ce);
  display: inline;
  border-radius: 50px;
  padding: 0px 17px;
  border: 1.8px solid #3f2783;
  font-weight: 600;
  width: 80px;
  height: 80px;
  line-height: 81px;
}

.pro-sidebar-layout {
  overflow: hidden !important;
}

.inputHip {
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 20px;
  color: #3f2783;
  padding: 0px 26px 0px 14px;
  height: 40px;
  background: transparent;
  margin-bottom: 12px;
  width: 70px;
  box-shadow: none;
  max-width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  white-space: break-spaces;
}

.img_white {
  position: relative;
  display: inline-block;
}

.img_hover {
  display: none;
  position: absolute;
  top: 14px;
  right: 22px;
}

.img_hover_view {
  display: none;
  position: absolute;
  top: 14px;
}

.img_hover_doc {
  display: none;
  position: absolute;
  top: 14px;
}

.img_white:hover .img_hover_view {
  display: block;
}

.img_white:hover .img_hover {
  display: block;
}

.img_white:hover .img_hover_doc {
  display: block;
}

.img_white:hover .img_hover2 {
  display: block;
}

.img_white:hover .img_hover3 {
  display: block;
}

.img_hover2 {
  display: none;
  position: absolute;
  top: 14px;
  right: 22px;
}

.img_hover3 {
  display: none;
  position: absolute;
  top: 14px;
  right: 37px;
}

img.check {
  background-color: #3f2783;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  padding: 3px;
}

.expired {
  color: #f00;
}

.pending {
  color: #ff7800;
}

.incomplete {
  color: #006dff;
}

.icon_border {
  display: flex;
  border-radius: 20px;
  border: 1px solid;
  width: 145px;
  height: 30px;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
}

.main_multiple .multi_select_drp {
  /* width: 337px !important; */
  max-width: 100%;
}

/* input:focus {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
  } */

textarea:focus {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
}

.box-content-path h4 {
  font-weight: 500;
  font-size: 18px;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
  /* border-top: 1px solid rgba(173, 173, 173, 0.2); */
  margin-bottom: 5px;
  padding: 12px;
}

.box_fixed {
  min-height: 185px;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
  border-top: 0px solid rgba(173, 173, 173, 0.2) !important;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 0px solid rgba(173, 173, 173, 0.2) !important;
}

.form-control {
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 20px;
  color: #3f2783;
  padding: 0px 26px 0px 14px;
  height: 40px;
  background: transparent;
  margin-bottom: 12px;
  width: 100%;
  box-shadow: none;
  max-width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  white-space: break-spaces;
}

.form-select {
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 20px;
  color: #3f2783;
  padding: 0px 26px 0px 14px;
  height: 40px;
  margin-bottom: 12px;
  width: 100%;
  box-shadow: none;
}

.form-select-op {
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 20px;
  color: #3f2783;
  padding: 0 15px;
  height: 40px;
  margin-bottom: 12px;
  width: 100%;
  box-shadow: none;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 16px;
}

label,
.form-label {
  color: #3f2783;
  margin-bottom: 15px;
  font-weight: 600;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(63, 39, 131, 0.5);
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(63, 39, 131, 0.5);
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(63, 39, 131, 0.5);
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(63, 39, 131, 0.5);
}

.search-box .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(63, 39, 131, 0.3);
}

.custom_check_box [type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.custom_check_box [type="checkbox"]+label {
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #3f2783;
}

.custom_check_box.large [type="checkbox"]+label {
  padding-left: 40px !important;
  padding-right: 40px !important;
  line-height: 23px;
}

.custom_check_box [type="checkbox"]+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 100%;
  background: #fff;
}
.custom_check_boxE [type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.custom_check_boxE [type="checkbox"]+label {
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #3f2783;
}

.custom_check_boxE.large [type="checkbox"]+label {
  padding-left: 40px !important;
  padding-right: 40px !important;
  line-height: 23px;
}

.custom_check_boxE [type="checkbox"]+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 100%;
  background: #d2d4d6;
}

.custom_check_boxE.large [type="checkbox"]+label:before {
  width: 24px;
  height: 24px;
}
.custom_check_box.large [type="checkbox"]+label:before {
  width: 24px;
  height: 24px;
}

.custom_check_box [type="checkbox"]:checked+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #3f2783;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.custom_check_box.tick [type="checkbox"]:checked+label:after {
  background: url(../images/check.png) no-repeat;
  background-color: #3f2783;
  background-position: center;
  width: 18px;
  height: 18px;
  top: 1px;
  left: 1px;
}

.custom_check_box.disables [type="checkbox"]:checked+label:after {
  background: url(../images/check.png) no-repeat;
  background-color: #857f7f;
  background-position: center;
  width: 18px;
  height: 18px;
  top: 1px;
  left: 1px;
}

.custom_check_box.tick.large [type="checkbox"]:checked+label:after {
  width: 22px;
  height: 22px;
}

.custom_check_box.disables.large [type="checkbox"]:checked+label:after {
  width: 22px;
  height: 22px;
}

.custom_radio_box [type="radio"] {
  position: absolute;
  opacity: 0;
}

.custom_radio_box [type="radio"]+label {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
  line-height: 20px;
  font-weight: 400;
  display: inline-block;
  color: #3f2783;
}

.custom_radio_box [type="radio"]+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 100%;
  background: #fff;
}

.custom_radio_box [type="radio"]:checked+label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #3f2783;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.custom_radio_box.large [type="radio"]+label {
  padding-left: 40px !important;
  font-weight: 400;
}

.custom_radio_box.large [type="radio"]+label:before {
  width: 23px;
  height: 23px;
}

.custom_radio_box.large [type="radio"]:checked+label:after {
  width: 13px;
  height: 13px;
  top: 5.5px;
  left: 4.5px;
}

.custom_radio_box.tick [type="radio"]:checked+label:after {
  background: url(../images/check.png) no-repeat;
  background-color: #3f2783;
  background-position: center;
  width: 18px;
  height: 18px;
  top: 1px;
  left: 1px;
}

.custom_radio_box.tick.large [type="radio"]:checked+label:after {
  width: 22px;
  height: 22px;
}

.settings_main .custom_check_box [type="checkbox"]+label {
  padding-left: 40px;
  font-weight: 400;
  font-size: 14px;
}

.btn_disabled {
  width: 140px;
  height: 40px;
  border: 2px solid #d9d4e6 !important;
  background-color: #d9d4e6 !important;
  color: #7968a8 !important;
  cursor: default !important;
  border-radius: 20px;
  pointer-events: none;
}

.btn_disabled:hover,
.btn_disabled:focus {
  border: 2px solid #d9d4e6 !important;
  background-color: #d9d4e6 !important;
  color: #7968a8 !important;
  outline: none !important;
  pointer-events: none;
  text-decoration: none;
}

.SignaturePad {
  position: relative;
  border: 2px solid #3f2783;
  border-radius: 20px;
  margin-right: 0 !important;
}

.clearlink {
  position: absolute;
  right: 20px;
  top: 15px;
  color: #3f2783;
  line-height: 14px;
  z-index: 1;
}

.clearlink img {
  margin-right: 8px;
}

.btn_fill {
  width: 140px;
  height: 40px;
  color: #fff;
  background: #3f2783;
  border-radius: 20px;
  border: 2px solid #3f2783;
  display: inline-block;

  border: 2px solid #3f2783;
  font-size: 14px;
  padding: 0 22px;

  font-weight: 400;
  line-height: 38px;
}

.share_btn_fill {
  width: 100px;
  height: 30px;
  color: #fff;
  background: #3f2783;
  border-radius: 20px;
  border: 2px solid #3f2783;
  display: inline-block;
  border: 2px solid #3f2783;
  font-size: 14px;
  padding: 0 22px;
  font-weight: 400;
  line-height: 27px;
}

.btn_fill_setting {
  width: 140px;
  height: 40px;
  color: #fff;
  background: #3f2783;
  border-radius: 20px;
  border: 2px solid #3f2783;
  display: inline-block;
  border: 2px solid #3f2783;
  font-size: 14px;
  padding: 0 22px;
  font-weight: 400;
  line-height: 38px;
  margin-top: 36px
}

.btnp_fill {
  width: 160px;
  height: 50px;
  color: #fff;
  background: #3f2783;
  border-radius: 10px;
  border: 2px solid #3f2783;
  display: inline-block;

  border: 2px solid #3f2783;
  font-size: 9px;
  padding: 10px 20px;
  /* margin-top: 6px; */

  font-weight: bold;
  /* line-height: 38px; */
}

.btnpp_fill {
  width: 160px;
  height: 50px;
  color: #fff;
  background: #278344;
  border-radius: 10px;
  border: 2px solid #3f2783;
  display: inline-block;

  border: 2px solid #278330;
  font-size: 9px;
  padding: 10px 20px;

  font-weight: bold;
  /* line-height: 38px; */
}

.btn_fill:hover,
.btn_fill:focus {
  background: transparent;
  color: #3f2783;
  outline: none;
  text-decoration: none;
  border: 2px solid #3f2783;
}

.btn_fill1 {
  width: 140px;
  height: 40px;
  color: #fff;
  background: #3f2783;
  border-radius: 20px;
  border: 2px solid #3f2783;
  display: inline-block;

  border: 2px solid #3f2783;
  font-size: 12px;
  padding: 0 22px;

  font-weight: 400;
  line-height: 38px;
}

.btn_fill1:hover,
.btn_fill:focus {
  background: transparent;
  color: #3f2783;
  outline: none;
  text-decoration: none;
  border: 2px solid #3f2783;
}

.btn_nill1 {
  background: #F3F5F7;
  /* border: 1px solid #3f2783; */
  border-radius: 13px;
  color: #5a546a;
}

.index_click {
  background-color: #ECE9F3;
  color: rgb(63 39 131);
  border: 1px solid #3f2783;
}

.btn_danger {
  width: 140px;
  height: 40px;
  background: #ff0000;
  color: #fff;
  border-radius: 20px;
  border: 2px solid #ff0000;
  display: inline-block;
  font-weight: 600;
}

.btn_danger_1 {
  width: 140px;
  height: 40px;
  background: #ff0000;
  color: #fff;
  border-radius: 20px;
  border: 2px solid #ff0000;
  display: inline-block;
  font-weight: 600;
}

.btn_danger:hover,
.btn_danger:focus {
  background: transparent;
  color: #ff0000;
  outline: none;
  text-decoration: none;
  border: 2px solid #ff0000;
}

.btn_green {
  width: 140px;
  height: 40px;
  background: #6fcf97;
  color: #fff;
  border-radius: 20px;
  border: 2px solid #6fcf97;
  display: inline-block;
}

.btn_green_1 {
  width: 140px;
  height: 40px;
  background: #6fcf97;
  color: #fff;
  border-radius: 20px;
  border: 2px solid #6fcf97;
  display: inline-block;
}

.btn_green:hover,
.btn_green:focus {
  background: transparent;
  color: #6fcf97;
  outline: none;
  text-decoration: none;
  border: 2px solid #6fcf97;
}

.btn_green_outline {
  width: 140px;
  height: 40px;
  background: transparent;
  color: #6fcf97;
  border-radius: 20px;
  border: 2px solid #6fcf97;
  display: inline-block;
}

.btn_green_outline:hover,
.btn_green_outline:focus {
  background: #6fcf97;
  color: #fff;
  outline: none;
  text-decoration: none;
  border: 2px solid #6fcf97;
}

.btn_nill {
  background: transparent;
  border: none;
  color: #3f2783;
}

.btn_nill:hover,
.btn_nill:focus {
  background: transparent;
  border: none;
  color: #3f2783;
}

button.btn {
  font-size: 14px;
}

.btn_outline {
  border: 2px solid #3f2783;
  color: #3f2783;
  border-radius: 50px;
  font-size: 14px;
  padding: 0 22px;
  height: 40px;
  font-weight: 400;
  line-height: 38px;
  background: transparent;
}

.btn_outline3 {
  border: 2px solid #d6cfeb;
  color: #3f2783;
  border-radius: 50px;
  font-size: 14px;
  /* padding: 0 22px; */
  height: 20px;
  font-weight: 400;
  /* line-height: 38px; */
  background: transparent;
}

h4.box-title.f_22 {
  font-size: 18px !important;
}

span#new-task-add {
  color: rgb(63 39 131);
}

a,
a:hover {
  color: #3f2783;
  text-decoration: none;
}

.form-control::-webkit-input-placeholder {
  color: rgba(63, 39, 131, 0.7);
  font-size: 14px;
}

.form-control:-moz-placeholder {
  color: rgba(63, 39, 131, 0.7);
  font-size: 14px;
}

.form-control::-moz-placeholder {
  color: rgba(63, 39, 131, 0.7);
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  color: rgba(63, 39, 131, 0.7);
  font-size: 14px;
}

.form-select {
  font-size: 14px;
}

.multi_select_drp {
  /* max-width: 247px; */
}

.multi_select_drp2 {
 
  border-radius: 20px !important;
}
.multi_select_drp2 .css-1okebmr-indicatorSeparator,
.css-tlfecz-indicatorContainer {
  display: none;
  width: 0px;
}

.borderRadius10 {
  border-radius: 10px;
}

.multi_select_drp .css-yk16xz-control,
.multi_select_drp .css-1pahdxg-control,
.multi_select_drp .css-1s2u09g-control,
.time_picker .react-time-picker__wrapper {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 20px !important;
  color: #3f2783;
  padding: 0;
  min-height: 40px;
  margin-bottom: 12px;
  width: 94%;
  box-shadow: none;
  /* min-width: 285px; */
}

.multi_select_drp1 .css-yk16xz-control,
.multi_select_drp1 .css-1pahdxg-control,
.multi_select_drp1 .css-1s2u09g-control,
.time_picker .react-time-picker__wrapper {
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 20px;
  color: #3f2783;
  padding: 0;
  min-height: 40px;
  margin-bottom: 12px;
  width: 100%;
  box-shadow: none;

  /* min-width: 260px; */
}

.multi_select_drp1 .css-1hb7zxy-IndicatorsContainer {
  align-items: flex-start;
}

.multi_select_drp .css-1okebmr-indicatorSeparator,
.multi_select_drp .css-tlfecz-indicatorContainer,
.multi_select_drp span+.css-1gtu0rj-indicatorContainer {
  display: none;
}

.time_picker {
  margin-bottom: 6px;
}

.time_picker label,
.time_picker .form-label {
  color: #3f2783;
  margin-bottom: 15px;
  font-weight: 600;
  display: block;
}

.time_picker .react-time-picker__wrapper {
  width: 260px;
  padding: 0 15px;
}

.time_picker .react-time-picker__button svg {
  stroke: #3f2783;
}

.time_picker .react-time-picker__inputGroup__input {
  color: #3f2783;
}

.mr_35 {
  margin-right: 35px;
}

.mr_20 {
  margin-right: 20px;
}

.mr_15 {
  margin-right: 15px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_64 {
  margin-top: 64px;
}

.mt_4 {
  margin-top: 4px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.pt_20 {
  padding-top: 20px;
}

.pt_30 {
  padding-top: 30px;
}

.pt_50 {
  padding-top: 50px;
}

.pr_0 {
  padding-right: 0;
}

.pl_0 {
  padding-left: 0;
}

.pb_30 {
  padding-bottom: 30px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pb_5 {
  padding-bottom: 5px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pd_10 {
  padding: 10px;
}

.pd_25 {
  padding: 25px;
}

.bg_white {
  background-color: #fff;
}

h4.ml_0 {
  margin-left: 0px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_105 {
  margin-left: 105px;
}

.ml_4 {
  margin-left: 4px;
}

.mrg_0 {
  margin: 0;
}

h4.ml_0 {
  margin-left: 0px;
}

.adv_search_sec {
  padding: 0 25px;
}

.f_12 {
  font-size: 12px;
}

.f_16 {
  font-size: 16px !important;
}

.f_18 {
  font-size: 18px;
}

.f_40 {
  font-size: 40px;
  color: #000;
}

.f_24 {
  font-size: 24px;
}

.f_45 {
  font-size: 45px;
  color: #000;
}

.h_150 {
  height: 150px !important;
}

.h_200 {
  height: 200px !important;
}

.mxw_100,
.mxw_100 .form-select {
  max-width: 100%;
}

.w_100 {
  width: 100%;
}

.w_350 {
  width: 350px;
  margin: 0 auto;
}

.minheight_60 {
  min-height: 60px;
}

.mxw_260 {
  max-width: 260px !important;
}

.mxw_285 {
  max-width: 285px !important;
}

.mxw_140 {
  min-width: 140px !important;
}

.mt_data {
  min-height: 105px;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.mxh_520 {
  height: auto;
  max-height: 520px;
}

.mxh_520_patientrule {
  height: auto;
  max-height: 250px;
}

.mxh_820 {
  height: auto;
  max-height: 820px;
}

.mxw_520 {
  max-width: 520px;
}

.mx_760 {
  max-width: 760px;
}

.br_10,
.br_10 .form-select {
  /* border-radius: 10px; */
}

.mb_0 {
  margin-bottom: 0;
}

.mb_8 {
  margin-bottom: 8px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mt_40 {
  margin-top: 40px;
}

.mh_270 {
  min-height: 270px;
}

.data_export .mh_420 {
  min-height: 420px;
}

.mh_50 {
  min-height: 50px;
}

b,
strong,
label {
  font-weight: 600;
  margin-bottom: 14px;
}

.text-danger {
  color: #ff0000 !important;
}

.whitebox {
  background: #fff;
  border-radius: 20px;
  padding: 25px 20px;
  margin-bottom: 20px;
  position: relative;
}

.whiteboxSmallProfile {
  background: #fff;
  border-radius: 20px;
  padding: 8px 18px;
  margin-bottom: 20px;
}

.user-letter-smProfile {
  width: 40px;
  height: 40px;
  margin: 0;
  background: #5e60ce;
  border-radius: 50%;
  line-height: 37px;
  border: 2px solid #3f2783;
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}

.profile_pic_letter span {
  text-transform: capitalize;
}

.img_logo {
  width: 30px;
}

.whitebox.text-center.Dashboard_whitebox__3eZ74:hover .icon_style {
  animation: swing ease-in-out 0.4s 3 alternate;
}

.box-header {
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
  margin-bottom: 25px;
}

.box-title {
  margin: 0;
  font-weight: 600;
}

.f_700 {
  font-weight: 700;
}

.f_600 {
  font-weight: 600;
}

.f_500 {
  font-weight: 500;
}

.f_400 {
  font-weight: 400;
}

.custom_check_box.f_400 label {
  font-weight: 400;
}

.f_22 {
  font-size: 22px;
}

.mb_100 {
  margin-bottom: 100px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_30 {
  margin-bottom: 30px !important;
}

.mb_13 {
  margin-bottom: 13px;
}

.mb_15 {
  margin-bottom: 15px;
}

.ml_20 {
  margin-left: 20px;
}

.ml_25 {
  margin-left: 25px !important;
}

.pt_6 {
  padding-top: 6px;
}

.c_pointer {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .res_auto {
    max-width: 100%;
  }

  .count_icon {
    top: 27px;
  }
}

@media (min-width: 769px) {
  .res_auto {
    max-width: 350px;
  }
}

/* .w_90{
    width: 90%;
} */

.label_title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.ul_data_export {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.welcome-section {
  text-align: center;
  position: relative;
  padding: 60px 0;
}

table.custom-tbl.data_export_tbl thead tr th {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  padding: 10px 19px;
  width: 55px;
}

.dashboard_task_sec .add_text {
  background: rgba(63, 39, 131, 0.1);
  border: 1px solid #3f2783;
  border-radius: 10px;
  height: 60px;
  font-size: 14px;
  padding: 0 30px;
  max-width: 100%;
}

.dashboard_task_sec .addtask_section .add-field .form-group {
  width: calc(100% - 40px);
  display: inline-block;
}

.pagination-bottom {
  border-top: 1px solid rgba(63, 39, 131, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 120px 40px 20px;
  position: fixed;
  bottom: 0;
  left: 223px;
  width: calc(100% - 223px);
  background: #f3f5f7;
  z-index: 2;
}

.sidebar-collapsed .pagination.pagination-bottom {
  width: calc(100% - 120px);
  left: 104px;
}

.custom_sort_dropdown .custom_dropdown .arrow_img {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
}

.custom_sort_dropdown .custom_dropdown.show .arrow_img {
  transform: rotate(-180deg);
  transition: transform 0.2s linear;
}

.custom_sort_dropdown .custom_dropdown .btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu,
.formTimelineSec .custom_dropdown .dropdown-menu {
  width: 170px;
  border: 2px solid #3f2783;
  border-radius: 20px;
  background: #fff;
  color: #3f2783;
  z-index: 3;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item {
  color: rgba(63, 39, 131, 0.7);
  font-size: 14px;
  padding: 6px 20px;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item.active,
.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item:active,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item.active,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item:active {
  background-color: #e9ecef;
}

.view_all_note .custom_sort_dropdown .custom_dropdown .dropdown-menu {
  left: -96px !important;
}

.formTimelineSec button#dropdown-basic1 {
  position: absolute;
  right: -30px;
  top: -9px;
}

.formTimelineSec button#dropdown-basic1 {
  position: absolute;
  right: -20px;
  top: -10px;
}

.formTimelineSec .dropdown-toggle::after {
  display: none;
}

.formTimelineSec .dropdown-item .action_icons {
  height: 18px;
  width: 18px;
  margin-right: 20px;
}

.formTimelineSec .mp_items .dropdown-item a,
.formTimelineSec .mp_items .dropdown-item span {
  color: #3f2783;
  font-weight: 500;
  display: block;
  font-size: 14px;
}

.temp_white p.f_600.text-capitalize {
  margin-bottom: 12px;
}

.temp_white p.f_600.text-capitalize1 {
  margin-bottom: 12px;
  /* font-Weight: "bold"; */
}

.Updated_dt {
  font-size: 12px;
  margin-bottom: 0;
}

.action_small_dev .dropdown-item .action_icons {
  margin-right: 20px;
}

.switch-title {
  font-weight: 600;
  margin-bottom: 15px;
  color: #3f2783;
}

.custom_radio_switch input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.custom_radio_switch label {
  display: inline-block;
  width: 100px;
  background-color: rgba(63, 39, 131, 0.1);
  color: rgb(63 39 131 / 22%);
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 14px 10px;
  border: 1px solid #3f2783;
  box-shadow: inset 0 1px 3px rgba(63, 39, 131, 0.2),
    0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.custom_radio_switch input:checked+label {
  box-shadow: none;
  color: #3f2783;
  transition: color 200ms;
  background-color: #fff;
  border: 2px solid #3f2783;
}

.setting_temp {
  /* max-height: 624px; */
  /* overflow-x: auto; */
  overflow: hidden;
  overflow-y: auto;
}

.custom_radio_switch.switch_active input:not(:checked)+label {
  box-shadow: none;
  color: rgba(63, 39, 131, 0.2);
  transition: color 200ms;
  background-color: rgba(63, 39, 131, 0.1);
  border: 2px solid rgba(63, 39, 131, 0.2);
}

.custom_radio_switch label:hover {
  cursor: pointer;
}

.custom_radio_switch #switch_left+label {
  border-right-width: 1px;
}

.custom_radio_switch #switch_right+label {
  border-left-width: 1px;
}

.custom_radio_switch.switch_active #switch_left+label {
  border-right-width: 2px;
}

.custom_radio_switch.switch_active #switch_right+label {
  border-left-width: 2px;
}

.custom_radio_switch label:first-of-type {
  border-radius: 20px 0 0 20px;
}

.custom_radio_switch label:last-of-type {
  border-radius: 0 20px 20px 0;
}

.text_area.form-control {
  max-width: 100%;
  height: auto;
  padding: 10px;
}

.form-select:focus {
  border-color: #3f2783;
  outline: none;
  box-shadow: none;
  color: #3f2783;
}

.default_c {
  color: #3f2783;
}

table.custom-tbl tr .col-action {
  min-width: 180px;
}

table.custom-tbl.doctable tr .col-action {
  min-width: 0px;
}

.action_large_dev ul {
  /* min-width: 180px; */
}

.page_header {
  padding: 20px 0;
}

.page_header .ph_title {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.tableContainer {
  /* border-radius: 20px; */
  height: 60vh;
  min-height: 300px;
  /* padding: 0 10px 10px 10px; */
  overflow: auto;
  max-height: 60vh;
}


.tableContainer1 {
  /* border-radius: 20px; */
  /* height: 350px; */
  /* padding: 0 10px 10px 10px; */
  overflow: auto;
}

.tableContainer table thead {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  background: #fff;
  z-index: 2;
}

.tableContainer .infinite-scroll-component {
  overflow: inherit !important;
}

.panel {
  background: #fff;
  border-radius: 20px;
  margin-bottom: 15px;
  min-height: 80px;
}

.panel-heading {
  border-bottom: 1px solid rgba(63, 39, 131, 0.1);
}

.panel-footer {
  border-top: 1px solid rgba(63, 39, 131, 0.1);
}

.panel_new {
  background: #fff;
  border-radius: 17px;
  margin-bottom: -13px;
  margin-top: -20px;
  min-height: 80px;
}

.panel_new-heading {
  border-bottom: 1px solid rgba(63, 39, 131, 0.1);
}

.panel_new-footer {
  border-top: 1px solid rgba(63, 39, 131, 0.1);
}

.pannel_pd {
  padding: 10px 25px;
}
.pannel_pd2{
  padding: 0px 25px;
}

.pannel_pd_center {
  justify-content: center;
}

.p_25 {
  padding: 25px;
}

.pt_0 {
  padding-top: 0;
}

.adv_search_sec h4 {
  margin-bottom: 25px;
}

.welcome-section {
  text-align: center;
  position: relative;
  padding: 20px 0 60px 0;
}

.welcome-section h4 {
  font-weight: 600;
  font-size: 24px;
  margin: 20px 0 10px 0;
}

.welcome-section p {
  font-size: 18px;
}

.w-108 {
  width: 108px !important;
}

.w-378 {
  width: 378px !important;
}

.char_limit_txt {
  text-align: right;
  color: #3f2783;
}

.upload_details {
  color: #3f2783;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  padding-top: 15px;
  background: #ece9f3;
  width: 100%;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 15px;
}

.upload_details .file_icon {
  margin-right: 15px;
}

.upload_details .file_name {
  display: inline-block;
}

.modal-content {
  box-shadow: none;
  border: 2px solid #3f2783;
  border-radius: 20px;
}

.modal-content1 {
  box-shadow: none;
  /* border: 2px solid #3f2783; */
  border-radius: 20px;
  width: 90%;
  margin: auto;
}
.modal-content2 {
  box-shadow: none;
  /* border: 2px solid #3f2783; */
  border-radius: 20px;
  width: 90%;
  margin: auto;
  position: fixed;
  margin-left: 37%;
}

.modal-header {
  border-bottom-color: #f4f4f4;
}

.modal-title {
  font-weight: 600;
  font-size: 18px;
}

.terms__condi--content .trms_txt {
  margin-bottom: 10px;
}

.terms__condi--content .trms_txt_sub {
  margin-bottom: 10px;
  padding-left: 10px;
  display: block;
}

.terms__condi--content .trms_txt_sub_sub {
  padding-left: 10px;
  display: block;
}

.terms__condi--content .txt__sub_ttl {
  text-decoration: underline;
}

.terms__content_ppup_bdy {
  max-height: calc(100vh - 130px);
  overflow-y: scroll;
}

.terms__content_ppup_bdy::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #ccc;
}

.terms__content_ppup_bdy::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(63, 39, 131, 0.2);
}

.terms__condi--content .d__block {
  display: block;
}

.dashboard_left_panel {
  width: 275px;
  min-height: 200px;
}

.dashboard_right_panel {
  width: calc(100% - 277px);
  min-height: 200px;
}

.patient_notes_popup .modal-title {
  width: 100%;
}

.modal_xxs {
  width: 200px;
}


/* schedular checkbox */
.scrollbar_schedular{
  overflow: auto;
}
.scrollbar_schedular::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #fff;
}
.scrollbar_schedular::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scrollbar_schedular::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(63, 39, 131, 0.2);
}


.scrollbar_vert {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollbar_hori {
  overflow-y: hidden;
  overflow-x: auto;
}

.scrollbar_vert::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollbar_vert::-webkit-scrollbar {
  width: 8px;
  background-color: #fff;
}

.scrollbar_vert::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(63, 39, 131, 0.2);
}

.scrollbar_hori::-webkit-scrollbar-track {
  border-radius: 5px;
}

.scrollbar_hori::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #fff;
}

.scrollbar_hori::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: rgba(63, 39, 131, 0.2);
}

.initial_value {
  color: #ff0000;
}

.recent_value {
  color: #006dff;
}

.carousel-item {
  min-height: 300px;
  margin-bottom: 20px;
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  border-radius: 50%;
  height: 10px;
  background-color: #3f2783 !important;
}

.carousel-control-prev-icon {
  background-image: url(../images/arrow-blue.png);
  transform: rotate(90deg);
}

.carousel-control-next-icon {
  background-image: url(../images/arrow-blue.png);
  transform: rotate(270deg);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-size: inherit;
  filter: none !important;
}

.CircularProgressbar .CircularProgressbar-text {
  font-weight: 600;
}

.patient_demographic .detail__txt .form-group {
  display: inline-block;
}

.patient_demographic .form-group {
  max-width: 260px;
  width: 100%;
}

.patient_demographic .form-group .form-select {
  border-width: 1px;
  margin: 5px 0;
}

.patient_demographic.form_disabled .form-group .form-select {
  border-width: 0px;
  margin: 5px 0;
}

.patient_demographic.form_disabled input,
.patient_demographic.form_disabled select {
  padding-left: 10px;
  padding-top: 4px;
  border: 0 solid #efefef;
  background: white;
  height: auto;
  color: #000 !important;
  transition: all 0.2s ease-in-out;
}

.patient_demographic .country_c {
  width: 110px;
}

.patient_demographic.form_disabled .country_c {
  width: 54px;
}

.patient_demographic.form_disabled .mobile__num input,
.patient_demographic.form_disabled .country_c input {
  padding-top: 0;
  margin-top: 0;
}

.score_range_slider .range_labels li.active {
  background: #fff;
  border: 2px solid #3f2783;
  color: #3f2783;
}

.score_range_slider .range_labels li.active span {
  opacity: 1;
  visibility: visible;
}

.patient_assessment_form .input_tab_box {
  border: 2px solid #3f2783;
  border-radius: 20px;
  padding: 25px;
  margin-right: 0 !important;
}

.range_sl_title {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
}

.form-group p.rd_label_title {
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.pt_step_rd label {
  font-weight: 400;
}

.assm_back_btn span {
  display: inline-block;
  color: #3f2783;
  font-size: 24px;
  margin-left: 10px;
}

.button_box {
  margin-top: 100px;
}

.button_box .next_btn {
  width: 264px;
  height: 73px;
  font-size: 24px;
  line-height: 65px;
  border-radius: 50px;
}

.button_box .next_btn span {
  margin-left: 10px;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.button_box .next_btn span img:first-child {
  display: none;
}

.button_box .next_btn:hover span img:first-child {
  display: inline-block;
}

.button_box .next_btn:hover span img:last-child {
  display: none;
}

.patient_assessment_form .accordion-item {
  border-radius: 20px;
}

.patient_assessment_form .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 24px;
  font-weight: 600;
}

.patient_assessment_form .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.patient_assessment_form .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #aab3bf00;
  box-shadow: none;
}

.patient_assessment_form .accordion-button:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.patient_assessment_form .accordion_tabpanel .accordion-button::after {
  background-image: url("../images/plus.png");
}

.patient_assessment_form .accordion_tabpanel .accordion-button:not(.collapsed)::after {
  background-image: url("../images/minus.png");
  height: 2px;
}

.patient_assessment_form .custom_radio_switch label {
  width: 160px;
}

.patient_assessment_form .accordion_tabpanel .switch-title {
  color: #000;
}

.settings_ppup_main .modal-content {
  /* min-height: 730px; */
}

.settings_ppup_main .modal-header {
  border-color: rgba(63, 39, 131, 0.1);
  padding: 20px;
}

.settings_ppup_main .settings_ppup_bdy {
  padding: 0;
}

.settings_ppup_main .modal-header .btn-close {
  background: transparent url(../images/close-popup.svg) center/1em auto no-repeat;
}

.settings_main .nav-pills .nav-link.active,
.settings_main .nav-pills .nav-link:hover {
  color: #3f2783;
  font-weight: 600;
  background-color: rgba(63, 39, 131, 0.1);
}

.settings_main .nav-pills .nav-link img {
  height: 20px;
  width: 25px;
  margin-right: 8px;
  margin-top: -1px;
}

.settings_main .nav-pills .nav-link .blue_icon {
  display: none;
}

.settings_main .nav-pills .nav-link.active .grey_icon,
.settings_main .nav-pills .nav-link:hover .grey_icon {
  display: none;
}

.settings_main .nav-pills .nav-link.active .blue_icon,
.settings_main .nav-pills .nav-link:hover .blue_icon {
  display: inline-block;
}

.settings_main .language_drp_dwn .form-select {
  border-radius: 10px;
  min-height: 50px;
}

.form-group select {
  background: url(../images/download2.png) no-repeat right;
  background-size: 26px;
  cursor: pointer;
  margin-bottom: 12px !important;
  white-space: nowrap;
}

.react-datepicker__input-container .form-control {
  background: url(../images/calendar.png) no-repeat right;
  background-size: 18px;
  background-position: right 0.75rem center;
  cursor: pointer;
}

#orglisttableDiv {
  border: 1px solid #3f2783;
  border-radius: 20px;
  margin-bottom: 50px;
}

#orglisttableDiv table.custom-tbl thead tr th {
  padding: 16px 10px;
}

#orglisttableDiv table.custom-tbl tbody tr td {
  padding: 14px 10px;
}

#orglisttableDiv table.custom-tbl thead tr .col-orgid {
  width: 30%;
}

#orglisttableDiv table.custom-tbl thead tr .col-orgname {
  width: 50%;
}

#orglisttableDiv table.custom-tbl thead tr .col-status {
  width: 20%;
}

.initial.progress {
  height: 8px;
}

.recent.progress {
  height: 8px;
}

.initial .progress-bar {
  background-color: #ff0000;
  height: 8px;
  border-radius: 5px;
  width: 100%;
}

.recent .progress-bar {
  background-color: #006dff;
  height: 8px;
  border-radius: 5px;
  width: 100%;
}

.opn_subtitle {
  border-bottom: 1px solid rgba(63, 39, 131, 0.1);
  padding-bottom: 15px;
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 600;
}

.opn_subtitle_left {
  padding-bottom: 15px;
  margin: 0 !important;
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 600;
}

.br_btm {
  border-bottom: 1px solid rgba(63, 39, 131, 0.1);
}

.fullw-separator {
  border-top: 1px solid rgba(63, 39, 131, 0.1);
  margin: 0 -25px;
  width: 103%;
  margin-top: 15px;
  padding-top: 15px;
}

.op_typelist_col {
  margin: 20px 20px 0px 0;
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 20px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 215px;
  height: 225px;
  padding: 10px;
  position: relative;
}

.op_typelist_col_title {
  margin: 20px 20px 0px 0;
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-radius: 20px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 215px;
  height: 245px;
  padding: 10px;
  position: relative;
}

.op_typelist_col .op_type_radio {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  text-align: right;
}

.op_typelist_col .op_type_radio input {
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
}

.op_typelist_col .op_typelist-content {
  margin-top: 25px;
}

.ideberg_sec .op_typelist_col .op_typelist-content {
  margin-top: 5px;
}

.op_typelist_col [type="radio"]+label {
  padding-right: 3px;
  margin-top: 10px;
}

.op_typelist_col .op_typelist-content p {
  display: block;
  font-weight: 600;
  color: #3f2783;
  margin-top: 15px;
}

.inst_reset_btn {
  position: absolute;
  right: 20px;
  top: 24px;
  font-weight: 600;
  cursor: pointer;
}

.position_rl {
  position: relative;
}

.artho_walch_sec .op_list_Img {
  min-height: 100px;
  margin-bottom: 20px;
}

.artho_gupta_seebauer .op_typelist-content {
  margin-top: 0;
}

.artho_gupta_seebauer .op_typelist_col {
  height: 236px;
}

.artho_gupta_seebauer .mb_10 {
  margin-bottom: 10px !important;
}

.create_newnote_pop .modal-dialog {
  max-width: 1177px;
  margin-top: 100px;
}

.create_newnote_pop .modal-header {
  border-bottom-color: rgba(63, 39, 131, 0.1);
  padding: 20px 30px;
}

.create_newnote_pop .modal-title {
  font-size: 18px;
  color: #000;
  line-height: 24px;
  text-align: center;
}

.create_newnote_pop .form-group {
  margin-bottom: 20px;
}

.create_newnote_pop .create_newnote_pop_bdy.modal-body {
  padding: 20px 30px 40px 30px;
}

.saveedNote_rightPop .search-box,
.view_all_note .search-box,
.nav_inner_search .search-box {
  width: 287px;
  position: relative;
  border: 1px solid rgba(63, 39, 131, 0.7);
  margin: inherit;
  border-radius: 20px;
  height: 40px;
  text-align: center;
  margin-top: 16px;
}

.nav_inner_search .search-box {
  margin-top: 0;
}

.search-box .input-group {
  width: 100%;
}

.search-box .input-group .form-control {
  border: 0;
  height: 36px;
  padding: 0 5px;
  color: #3f2783;
  font-size: 18px;
  margin-top: 0px;
  text-align: left;
}

.search-box .input-group .input-group-addon {
  border: 0;
  background: transparent;
  padding: 0;
  width: 50px;
  text-align: left;
  height: 36px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.search-box .input-group .input-group-addon.first img:last-child {
  display: none;
}

.search-box .input-group.search_active .input-group-addon.first img:last-child {
  display: block;
}

.search-box .input-group.search_active .input-group-addon.first img:first-child {
  display: none;
}

.saveedNote_rightPop .search-box .input-group .form-control,
.view_all_note .search-box .input-group .form-control,
.nav_inner_search .search-box .input-group .form-control {
  font-size: 14px;
}

.form-control:focus {
  color: #3f2783;
  outline: 0;
  box-shadow: none;
  border-color: #3f2783b5 !important;
}

.select__control.css-1s2u09g-control {
  border: 2px solid rgba(63, 39, 131, 0.7);
  border-color: #3f2783b5 !important;
  border-radius: 19px;
}

.basic-multi-select.css-b62m3t-container .select__control.css-1s2u09g-control {
  border-radius: 0.25rem !important;
}

.basic-multi-select.css-b62m3t-container .css-1pahdxg-control:hover {
  border-radius: 0.25rem !important;
}

.basic-multi-select.css-b62m3t-container .css-1pahdxg-control:focus {
  border-radius: 0.25rem !important;
}

.basic-multi-select.css-b62m3t-container .css-1pahdxg-control {
  border-radius: 0.25rem !important;
}

.css-1pahdxg-control {
  border: 1px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 30px !important;
  border-color: #3f2783b5 !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:focus {
  border: 1px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 30px !important;
  border-color: #3f2783b5 !important;
  box-shadow: none !important;
}

.main_select.css-1pahdxg-control:hover {
  border: 1px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 30px !important;
  border-color: #3f2783b5 !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border: 1px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 30px !important;
  border-color: #3f2783b5 !important;
  box-shadow: none !important;
}

.css-14el2xx-placeholder {
  color: #3f2783 !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}

.fc .fc-button-primary {
  background-color: #ffffff !important;
  border-color: #3f2783 !important;
  color: #3f2783;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-button:focus {
  box-shadow: none !important;
  outline: 0;
}

.fc-h-event {
  background-color: #3f2783e6 !important;
  border: 1px solid #3f278338 !important;
  display: block;
}

button.fc-today-button.fc-button.fc-button-primary {
  background-color: #3f2783 !important;
  border-radius: 50px;
  color: #fff;
  width: 140px;
}

.fc .fc-toolbar-title {
  font-size: 1.75em;
  text-transform: capitalize !important;
  margin: 0;
}

.fc .fc-button-primary:hover {
  color: #3f2783e6 !important;
}

button.fc-today-button.fc-button.fc-button-primary:hover {
  color: #fff !important;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}

.fc-theme-standard .fc-scrollgrid {
  border-radius: 10px !important;
}

button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
  margin-right: 0px !important;
  border-radius: 50px !important;
  text-transform: capitalize !important;
}

button.fc-dayGridMonth-button.fc-button.fc-button-primary {
  border-radius: 50px !important;
  text-transform: capitalize !important;
  width: 80px;
}

img.w-25.icon_style {
  height: 40px;
}

button.fc-timeGridWeek-button.fc-button.fc-button-primary {
  margin-right: 5px !important;
  margin-left: 5px !important;
  border-radius: 50px !important;
  text-transform: capitalize !important;
  width: 80px;
}

button.fc-listWeek-button.fc-button.fc-button-primary {
  text-transform: capitalize;
  margin-left: 5px !important;
  width: 80px;
  border-radius: 50px !important;
}

button.fc-listWeek-button.fc-button.fc-button-primary.fc-button-active {
  text-transform: capitalize;
  margin-left: 5px;
  border-radius: 50px;
}

.fc-v-event {
  background-color: #3f2783 !important;
  border: 1px solid #3f2783 !important;
  display: block;
}

.fc-timegrid-axis-frame.fc-scrollgrid-shrink-frame.fc-timegrid-axis-frame-liquid {
  text-transform: capitalize !important;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  text-transform: uppercase !important;
}

button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  margin-left: 5px !important;
}

button.fc-timeGridDay-button.fc-button.fc-button-primary {
  border-radius: 50px !important;
  text-transform: capitalize !important;
  width: 80px;
}

button.fc-today-button.fc-button.fc-button-primary {
  text-transform: capitalize !important;
}

button.fc-prev-button.fc-button.fc-button-primary {
  margin-right: 5px !important;
  border-radius: 50px !important;
}

.fc-event-main {
  text-transform: capitalize !important;
}

.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  text-transform: capitalize !important;
}

.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  text-transform: capitalize !important;
}

button.fc-next-button.fc-button.fc-button-primary {
  border-radius: 50px !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #3f2783 !important;
  color: #fff !important;
}

.fc .fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid #3f2783 !important;
}

.fc-list-event {
  background-color: #fff !important;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: #ece9f3;
}

button.fc-multiMonthYear-button.fc-button.fc-button-primary {
  text-transform: capitalize;
  margin-right: 5px !important;
  margin-left: 5px !important;
  border-radius: 50px !important;
  text-transform: capitalize !important;
  width: 80px;
}

/* button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
    opacity: .69 !important;
}
button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active{
    opacity: .69 !important;
}
button.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active{
    opacity: .69 !important;
}
button.fc-listWeek-button.fc-button.fc-button-primary.fc-button-active{
    opacity: .69 !important;
}
button.fc-multiMonthYear-button.fc-button.fc-button-primary.fc-button-active {
    opacity: .69 !important;
} */
.fc .fc-button:disabled {
  opacity: 1 !important;
}

.org_tab .nav-tabs>li>button.active {
  border: 2px solid rgba(63, 39, 131, 0.7);
  z-index: 1;
}

.org_tab .nav-tabs>li>button.active {
  border: 2px solid rgba(63, 39, 131, 0.7);
  z-index: 1;
}

.org_tab .nav-tabs .nav-link:focus,
.org_tab .nav-tabs .nav-link:hover {
  border-color: rgba(63, 39, 131, 0.7);
}

.org_tab .tab-content,
.org_tab .tab-content>div {
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.org_tab .tab-content {
  padding: 0 !important;
  margin-top: -1px;
  /* margin-bottom: 100px; */
}

.org_tab .nav-tabs>li>button {
  line-height: 20px;
  font-weight: 600;
  font-size: 12px;
  min-width: 140px;
  height: 44px;
  position: relative;
  margin-right: 7px;
  border-radius: 20px 20px 0 0;
  cursor: default;
  background-color: #fff;
  border: 2px solid rgba(63, 39, 131, 0.7);
  display: block;
  padding: 10px 12px;
  border-bottom: none !important;
}


/* .fc .fc-button:disabled {
    opacity: 1 !important;
} */
.search-box .search_close_icon {
  display: none;
}

.calendar_ {
  margin-left: 10px;
  /* overflow: hidden; */
  margin-right: 10px;
  overflow: hidden;
}

.fc .fc-timegrid-slot {
  height: 2.5em;
}

.fc .fc-col-header-cell-cushion {
  font-weight: 600 !important;
  padding: 10px 6px;
}

/* th.fc-col-header-cell.fc-day.fc-day-fri.fc-day-today .fc-scrollgrid-sync-inner a {
    margin-left: -160px;
} */
/* a.fc-col-header-cell-cushion {
    margin-left: -160px;
} */

.search-box .search_active .search_close_icon {
  display: block;
}

.chec_lb_before {
  color: #3f2783;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}

.chec_lb_before label {
  margin-bottom: 0;
}

.chec_lb_before input {
  margin: 0 10px;
  width: 16px;
  height: 16px;
}

.time_interval_filed label {
  /* margin-bottom: 76px; */
}

.doctor_roles_map_screen {
  max-width: 480px;
  margin: 0 auto;
}

.doctor_roles_map_screen .button_box {
  margin-top: 20px;
  /* padding: 9px; */
}

.doctor_roles_map_screen .button_box button {
  border-radius: 10px;
}

.pl45 {
  padding-left: 45px !important;
}

.content__main_reg {
  width: 100%;
}

.dc_reg_inner {
  /* width: 500px; */
  margin: 0 auto;
}

.css-12jo7m5 {
  text-transform: capitalize;
}

.rmsc .dropdown-container {
  border: 0px solid var(--rmsc-border) !important;
}

.rmsc .dropdown-container {
  position: relative;
  outline: 0;
  background-color: var(--rmsc-bg);
  border: 0px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius);
}

.rmsc .dropdown-heading {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 20px !important;
  position: relative;
  padding: 0 var(--rmsc-p);
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--rmsc-h);
  cursor: default;
  outline: 0;
}

.user-letter img {
  width: 44px;
  height: 44px;
  margin: -3px;
  background: #5e60ce;
  border-radius: 50%;

  border: 2px solid #3f2783;
}

.large img {
  width: 95px;
  height: 95px;
  margin: -3px;
  background: #5e60ce;
  border-radius: 50%;

  border: 2px solid #3f2783;
}

.location_ select.form-control.form-select {
  border: 1px solid rgba(63, 39, 131, 0.7);
  min-width: 140px;
  font-size: 14px;
}

.no_data {
  padding: 20px;
  text-align: center;
  font-size: 18px;
  color: #8f8e8e;
  padding-bottom: 100px;
  padding-top: 100px;
}

.card_heigh h5 {
  min-height: 40px;
}

.card_heigh p {
  min-height: 33px;
}

.no__data--main {
  background: #fff;
  width: 100%;
  border-radius: 20px;
  text-align: center;
  padding: 70px 30px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.svg_disbabled {
  z-index: -1;
  pointer-events: none;
}

table.custom-tbl tbody tr td {
  font-size: 14px;
  text-transform: capitalize;
  color: #3f2783;
  padding: 14px 2px;
  border-top: 1px solid rgba(63, 39, 131, 0.1);
}

/* -------------------- */
.table-borderless {
  width: 100%;
}

table.table-borderless tr td {
  font-size: 14px;
  text-transform: capitalize;
  color: #3f2783;
  padding: 14px 2px;
}

table.table-borderless tr .td-bold {
  font-weight: 800;
  color: #000;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #3f2783;
  display: block;
  margin-left: 13px;
  position: relative;
}

.css-tj5bde-Svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  color: #3f2783;
}

.css-1u9des2-indicatorSeparator {
  background-color: #fff !important;
}

.css-t3ipsp-control {
  border-color: #3f2783 !important;
  border-radius: 20px !important;
  color: #3f2783 !important;
  padding: 0 !important;
  min-height: 40px !important;
  margin-bottom: 12px !important;
  width: 100% !important;
  box-shadow: none !important;
}

.css-t3ipsp-control:hover {
  border-color: 2px solid #3f2783 !important;
  border-radius: 20px !important;
  color: #3f2783 !important;
  padding: 0 !important;
  min-height: 40px !important;
  margin-bottom: 12px !important;
  width: 100% !important;
  box-shadow: none !important;
}

.css-t3ipsp-control:active {
  border-color: #3f2783 !important;
  border-radius: 20px !important;
  color: #3f2783 !important;
  padding: 0 !important;
  min-height: 40px !important;
  margin-bottom: 12px !important;
  width: 100% !important;
  box-shadow: none !important;
}

.css-13cymwt-control {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 20px !important;
  color: #3f2783 !important;
  padding: 0 !important;
  min-height: 40px !important;
  margin-bottom: 12px !important;
  width: 100% !important;
  box-shadow: none !important;
}

.css-v7duua {
  color: #dc3545 !important;
}

span.user_letter img {
  width: 44px;
  height: 44px;
  background: #5e60ce;
  border-radius: 50%;
  border: 2px solid #3f2783;
}

/* span.pro-item-content {
    display: contents;
} */
.pathology_page .img_box {
  min-height: 90px;
}

.pathology_page .dt_individual_top_sec .whitebox h4 {
  padding-top: 27px;
  padding-bottom: 15px;
}

.btn-outline-common {
  border: 2px solid #3f2783;
  color: #3f2783;
  border-radius: 50px;
  font-size: 18px;
  padding: 0 22px;
  height: 40px;
  line-height: 40px;
  background: transparent;
}

.pathology_page {
  padding-top: 100px;
}

.box-content-path {
  max-height: 308px;
  padding: 40px 20px;
  height: 301px;
}

.notes_text_descrip {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding-right: 82px !important;
  max-width: 300px;
}

.notes_text_title {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding-right: 82px !important;
  max-width: 300px;
}

.dc_reg_inner p {
  word-break: break-word;
}

.img_template {
  margin-bottom: 20px;
  border: 1px solid #3f2783;
  border-radius: 6px;
  max-width: 186px;
}

.temp_des {
  max-width: 208px;
  margin-bottom: -28px;
  /* margin-top: 30px; */
}

.temp_title_delete {
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  margin-bottom: 6px;
}

span#letter-tab {
  margin-bottom: 15px;
}

.mxh_910 {
  height: auto;
  max-height: 800px;
}

p.text_temp {
  font-size: 17px;
  border-bottom: 1px solid #000000;
  width: fit-content;
  font-weight: 500;
}

.progress_bar {
  font-size: 15px;
  color: #1dce00;
  font-weight: 400;
}

.input_search:focus::-webkit-input-placeholder {
  color: #3f2783 !important;
}

table.table-borderless.table_sche_model tr td {
  padding: 2px 2px;
}

table.my_clinic tbody tr td {
  padding-right: 44px;
}

.dasbord_height {
  height: 400px !important;
  min-height: 366px;
}

span.add_btn img {
  /* margin-left: 6px; */
}

span.add_btn label {
  margin-bottom: 0px;
  padding: 3px 4px 2px 3px;
}

.add_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #3f2783;
  font-weight: 500;
  cursor: pointer;
  /* font-size: 10px; */
  border: 2px solid;
  border-radius: 20px;
}

span.add_btn img {
  width: 20px;
  padding: 2px;
}

.microphone_box {
  position: absolute;
  bottom: 8px;
  right: 0;
  display: inline-flex;
}

.pulseLoader {
  position: absolute;
  top: -17px;
  right: 0px;
  width: 60px;
  left: -5px;
  height: 60px;
  border-radius: 50%;
  background-color: #ff0000;
  outline: 1px solid transparent;
  animation: pulse 1.9s ease-in-out infinite;
}

.whitebox.text-center.Dashboard_whitebox__3eZ74:hover {
  box-shadow: 1px 4px 9px 4px #3f278340;
}

.whitebox.text-center.Dashboard_whitebox__3eZ74:hover .btn_outline {
  background: #3f2783 !important;
  color: #fff !important;
}

.btn_outline:hover {
  background-color: #3f2783;
  color: #fff;
}

.css-1okebmr-indicatorSeparator {
  background-color: hsl(0deg 0% 100%) !important;
}

div#pdf-controls {
  display: flex;
  position: -webkit-sticky;
  position: absolute !important;
  top: -7px !important;
  left: 0 !important;
  right: 50px;
  z-index: 1;
  justify-content: flex-end;
  padding: 8px;
  background-color: #ffffff00 !important;
  box-shadow: none !important;
}

.content_text {
  -webkit-transform: rotate(-24deg);
  -moz-transform: rotate(-24deg);
  -o-transform: rotate(-24deg);
  writing-mode: lr-tb;
}

.span-btn {
  padding: 10px;
  background-color: #3f2783;
  color: #ffffff;
  border-radius: 30px;
  border: 2px solid #3f2783;
}

.span-btn:hover,
.span-btn:focus {
  background: transparent;
  color: #3f2783;
  outline: none;
  text-decoration: none;
  border: 2px solid #3f2783;
}

.box-content.card_heigh p {
  display: none;
}

.btn_org {
  background-color: #3f2783 !important;
  color: #fff !important;
}

.border_line {
  /* border: 1px solid #3f2783; */
  margin-top: 40px;
  margin-bottom: 40px;
}

.border_line {
  text-align: center;
  display: block;
}

.border_line:after {
  content: "";
  border: 1px solid #3f2783;
  /* margin-top: 40px; */
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}

.browser_file {
  padding: 8px 15px;
  border-radius: 50px;
  color: #ffffff;
  border: 2px solid #f8f9fa;
  width: 153px;
  /* margin-top: 24px; */
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin: auto;
  margin-bottom: 10px;
}

.patient_box {
  max-height: 210px;
  /* overflow: auto; */
}

.cynsqJ {
  display: none !important;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0.4;
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.msg-box {
  font-size: 15px;
  border: 1px solid #3f2783;
  border-radius: 8px;
  padding: 10px;
  text-align: justify;
}

.temp_middle_txt_:hover {
  opacity: 0.6;
}

.conta:hover .header-image {
  opacity: 0.3;
}

.conta:hover .middle {
  opacity: 1;
}

.header-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.text {
  background-color: #3f2783;
  color: white;
  font-size: 16px;
  border-radius: 40px;
  padding: 8px 28px;
  cursor: pointer;
}

div#header-bar {
  display: none;
}

.dropdown-container:focus-within {
  box-shadow: none !important;
  border-color: none !important;
}

a#pdf-download {
  display: none;
}

a.fc-daygrid-more-link.fc-more-link {
  font-size: 7px;
}

.fc-multiMonthYear-view.fc-view.fc-multimonth.fc-multimonth-multicol.fc-multimonth-compact {
  border-radius: 10px;
}

.fc-theme-standard .fc-popover {
  background: var(--fc-page-bg-color);
  border: 1px solid var(--fc-border-color);
  border-radius: 10px;
  z-index: 1;
}

.main_multiple .dropdown-heading {
  border-radius: 10px !important;
}

.main_select .select__control.css-1s2u09g-control {
  border-radius: 10px !important;
}

.main_select .select__control:hover {
  border-radius: 10px !important;
}

.main_select .select__control:focus {
  border-radius: 10px !important;
}

.main_select .select__control:active {
  border-radius: 10px !important;
}

.main_select .select__control:focus-visible {
  border-radius: 10px !important;
}

.main_select .select__control:focus-within {
  border-radius: 10px !important;
}

.main_select .select__control:visited {
  border-radius: 10px !important;
}

.main_select .select__control:target {
  border-radius: 10px !important;
}

.task-icon.view-task {
  /* display: none; */
  display: table-cell;
}

#task-list li:hover .task-icon.view-task {
  display: table-cell;
}

.custom_sort_dropdown.SortDropdown_sort_dropdown_main__dmlwO {
  margin-right: 25px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #ece9f3 !important;
}

.profile h4 {
  font-weight: 600;
}

.welcome-section-doc {
  text-align: center;
  position: relative;
  padding: 20px 0 60px 0;
}

.welcome-section-doc h4 {
  font-weight: 700;
  font-size: 24px;
  margin: 20px 0 10px 0;
  font-family: "Poppins";
}

.journey-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.journey-baground {
  width: 99px;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 20px;
}

.journey-content .journey-baground img {
  width: 60px;
  height: 60px;
  z-index: 2;
  cursor: pointer;
}

h4.box-title.f_22 {
  color: #000000;
}

button.btn-close:focus {
  box-shadow: none;
}

.notification.toast.top-right {
  border-radius: 10px;
}

.dropdown-content {
  z-index: 4 !important;
}

.data_export_marg {
  margin-bottom: 0px !important;
  white-space: nowrap !important;
}

.temp_mb {
  margin-bottom: 15px;
}

.box-content.card_heigh {
  min-height: 160px;
  margin-top: 10px;
}

.fc .fc-daygrid-day.fc-day-today,
.fc-timegrid-slot.fc-timegrid-slot-lane {
  background-color: #d9d4e6 !important;
}

.d__flex {
  display: flex;
}

.sec__details_three .posit__parent .text-danger {
  position: absolute;
}

.op_typelist-content .op_list_Img img {
  width: auto;
  height: 142px;
  max-width: 192px;
}

.op_typelist-content {
  position: relative;
}

p.rd_label_title {
  position: absolute;
  top: 149px;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

p.rd_label_title_1 {
  position: absolute;
  top: 175px;
  left: 50%;
  width: 110%;
  transform: translate(-50%, -50%);
}

.white_img img {
  width: 73px;
}

.white_img {
  width: 163.12px;
  padding: 20px;
  background: #ffffff;
  border-radius: 27.6471px;
  margin: auto;
}

.w_500 {
  width: 567.84px !important;
}

.img-border {
  display: flex;
  justify-content: center;
  /* Horizontally center the image */
  align-items: center;
  /* Vertically center the image */
  max-width: 120px;
  height: 120px;
  border: 1px solid #ece9f3;
  border-radius: 10px;
}

.img-border_partner {
  display: flex;
  justify-content: center;
  /* Horizontally center the image */
  align-items: center;
  /* Vertically center the image */
  width: 40px;
  height: 40px;
  border: 1px solid #ece9f3;
  border-radius: 10px;
}

.img-border img {
  width: 100%;
  padding: 15px;
}

.radio_txt h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 133.6%;
}

.radio_txt p {
  display: block !important;
}

/* .icon_style{
    width: 100%;
} */

.w-10 {
  width: 10%;
}

.w-90 {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.radio_box_fixed {
  min-height: 310px;
}

p.img-labeltxt {
  color: #3f2783;
  font-weight: 600;
  max-width: 223px;
  margin-top: 10px;
  text-align: inherit;
}

/* .temp_icon {
    position: absolute;
    top: 10px;
    right: 24px;
} */
.temp_icon {
  position: absolute;
  top: 15px;
  right: 24px;
}

.arrow-white-img {
  display: none;
}

.arrow-img {
  display: block;
}

.temp_btn:hover .arrow-white-img {
  display: block;
}

.temp_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 290px;
  border: 1px solid #d9d4e6;
  border-radius: 20px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
}

.temp_box:hover {
  box-shadow: 1px 4px 9px 4px #3f278399;
}

.temp_box {
  position: relative;
  /* margin: auto;  */
  margin-left: 15px;
  margin-bottom: 10px;
}

.temp_bottom_txt {
  position: absolute;
  width: 198px;
  height: 30px;
  bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 2px;
  color: #3f2783;
  text-align: left;
}

.temp_box img {
  /* width: 100px; */
}

.temp_white {
  /* background-color: #ffffff; */
  background-color: rgb(255 255 255 / 0%);

  height: 1px;
}

.temp_btn_top {
  position: absolute;
  top: 7px;
  left: 11px;
  background: #ffffff;
  border: 1px solid #d9d4e6;
  border-radius: 10px;
  font-size: 12px;
  color: #3f2783;
  min-width: 54px;
  height: 22px;
  line-height: 1px;
  padding: 10px;
  text-align: center;
}

.temp_btn_top2 {
  position: absolute;
  top: 7px;
  right: 11px;
  background: #ffffff;
  border: 1px solid #d9d4e6;
  border-radius: 10px;
  font-size: 12px;
  color: #3f2783;
  min-width: 54px;
  height: 22px;
  text-align: center;
  cursor: pointer;
}

.temp_btn_top2 img {
  max-width: 38px;
  padding: 8px;
  padding-top: 4px;
}

.temp_drop {
  min-width: 200px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.15);
  color: #3f2783;
  z-index: 3;
}

.temp_drop a {
  color: rgba(63, 39, 131, 0.7);
  font-size: 14px;
  padding: 6px 20px;
}

.temp_btn_top2 button {
  padding: 0px;
}

.temp_btn_top2 button:after {
  display: none;
}

.temp_middle_txt p {
  font-size: 7px;
  padding: 12px;
  max-height: 179px;
  overflow: hidden;
  overflow-y: auto;
}

.treatment-col img {
  /* min-width: 100px; */
  max-width: 160px;
}

span.btn_Fav {
  border: 2px solid #3f2783;
  color: #3f2783;
  border-radius: 50px;
  font-size: 14px;
  padding: 0 22px;
  display: inline-block;
  height: 40px;
  font-weight: 400;
  line-height: 38px;
  background: transparent;
  cursor: pointer;
}

.whitebox_template {
  background: #fff;
  border-radius: 20px;
  max-width: 961px;
  padding: 10px 10px;
  position: relative;
  margin: auto;
}

.template_btn {
  /* background: #fff; */
  border-radius: 20px;
  max-width: 961px;
  padding: 10px 10px;
  position: absolute;
  margin: auto;
  margin-top: 25px;
  right: 0;
  top: 80px;
}

.request_view {
  border: 2px solid #3f2783;
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 60px;
  background: #ffffff;
}

.request_view1 {
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 60px;
  background: #F3F5F7;
  margin-right: 20px;
  margin-left: 20px;
}


.request_view span {
  display: block;
  font-size: 14px;
  padding: 7px 7px;
  align-items: center;
}

.request_view1 span {
  display: block;
  font-size: 14px;
  padding: 7px;
  align-items: center;
}

.temp_middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/* .hover_box_:hover{
    opacity: 0.3;
    transition: .5s ease;
    backface-visibility: hidden;
} */
.hover_box_:hover .temp_middle {
  opacity: 1;
}

.text_temp_hover {
  background-color: #3f2783;
  color: white;
  font-size: 12px;
  border-radius: 40px;
  padding: 5px 16px;
}

.css-1s2u09g-control {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 30px !important;
  background-color: #fff !important;

}

.css-1s2u09g-control:hover {
  border-color: rgba(63, 39, 131, 0.7) !important;
}

.accordion_bg {
  color: #3f2783 !important;
  background-color: rgba(63, 39, 131, 0.1) !important;
  font-weight: 500;
}

.accordion_bg:focus {
  z-index: 3;
  border-color: #3f2783 !important;
  outline: 0;
  box-shadow: 0 0 0 0.05rem rgba(63, 39, 131, 0.7) !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.8rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background: url(../images/download2.png) no-repeat right !important;

  transition: transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
  background: url(../images/download2.png) no-repeat right !important;
  background-repeat: no-repeat;
}

.max_length {
  max-width: 302px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.a4-page {
  /* width: 21cm;
    min-height: 29.7cm; */
  /* aspect-ratio: 21/29.7; */
  width: 595px;
  height: 810px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.header-image,
.footer-image {
  position: absolute;
  width: 100%;
}

.header-image {
  top: 0;
  aspect-ratio: 5;
  width: -webkit-fill-available;
  margin: auto;
}

.footer-image {
  bottom: 0;
}

.content {
  width: 100%;
  margin-top: 5cm;
  /* border: 1px solid black; */
}

.top-content {
  display: inline-block;
}

.bottom-content {
  display: inline-block;
}

header {
  height: 6vh;
}

/* ------- squeeze wrapper --------- */
.wrapper {
  padding: 2px;
}

.resizeable-box {
  position: absolute;
  /* background-color: rgb(231, 231, 231); */
  border: 0;
  width: 98%;
  /* height: 200px; */
  /* display: flex; */
  /* min-height: 600px;
    min-width: 400px; */
  /* cursor: move; */
}

.resizeable-box:hover {
  border: 1px solid rgb(185, 185, 185);
}

.resizer {
  position: absolute;
  /* background-color: rgb(231, 231, 231); */
}

.rr {
  cursor: col-resize;
  height: 100%;
  width: 2px;
  top: 0;
  right: 0;
  /* border: 1px solid rgb(248, 248, 248); */
}

.rt {
  cursor: row-resize;
  height: 2px;
  left: 0;
  top: 0;
  width: 100%;
  /* border: 1px solid rgb(248, 248, 248); */
}

.rb {
  cursor: row-resize;
  height: 2px;
  left: 0;
  bottom: 0;
  width: 100%;
  /* border: 1px solid rgb(248, 248, 248); */
}

.rl {
  cursor: col-resize;
  height: 100%;
  width: 2px;
  left: 0;
  top: 0;
  /* border: 1px solid rgb(149, 147, 147); */
}

/* .footer-image {
    border: 1px solid black;
  } */
/* .header img, .footer img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .letter {
      width: 21cm;
      height: 29.7cm;
      margin: auto;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding-top: 10%;
      padding-bottom: 10%;
    }
    
    .header,
    .footer {
      position: absolute;
      width: 100%;
      height: 6%;
      left: 0;
      text-align: center;
    }
    
    .header {
      top: 0;
    }
    
    .footer {
      bottom: 0;
    }
    
    .header img,
    .footer img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
    
    .content {
      width: 80%;
      margin: 0 auto;
      padding-top: 50px;
    } */

/* @media print {
      .header img,
      .footer img {
        max-height: 50px;
      }
    
      .content {
        padding-top: 0;
      }
    } */
/* .header img,
    .footer img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
    @media print {
      .header img,
      .footer img {
        max-height: 50px;
      }
    } */

.box {
  height: 140px;
  background-color: #f3f5f7;
}

.body {
  height: 80vh;
}

.page-small {
  /* width: 100%; */
  height: 5.7cm;
  aspect-ratio: 9/16 !important;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* border-radius: 12px; */
}

.header-images,
.footer-images {
  position: absolute;
  width: 100%;
}

.header-images {
  top: 0;
}

.footer-images {
  bottom: 0;
}

.contents {
  margin-top: 7em;
}

.content_text {
  -webkit-transform: rotate(-24deg);
  -moz-transform: rotate(-24deg);
  -o-transform: rotate(-24deg);
  writing-mode: lr-tb;
  font-size: 21px;
  color: #a5a1a1;
  margin-right: 38%;
  margin-left: 17%;
  /* margin: auto; */
  display: block;
}

.border_templ {
  border: 1px solid #3f2783;
  /* border-radius: 12px; */
  max-width: 186px;
  margin-top: 15px;
}

.border_templ:hover {
  cursor: pointer;
  box-shadow: 1px 4px 9px 4px #3f278340;
}

.temp_btn_img {
  position: absolute;
  top: 1px;
  right: 7px;
  cursor: pointer;
}

.temp_btn_img img {
  width: 35px;
}

.css-14el2xx-placeholder {
  color: rgba(63, 39, 131, 0.7) !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  max-width: 302px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Prescriber__control.css-1s2u09g-control {
  border-radius: 9px;
}

.temp_scrolTab {
  max-height: 50vh;
  overflow: auto;
  padding-bottom: 100px;
}

.dropdown_main_1 {
  width: 100%;
  display: flex;
  position: relative;
}

.dropdown_main_1 input {
  padding: 0 0 0 13px !important;
  background-color: rgba(0, 0, 0, 0);
  font-size: 14px;

  font-family: poppins;
}

.dropdown_main_1 input:active {
  border: none !important;
}

.dropdown_main_1 input:hover {
  border: none !important;
  background-color: #fff !important;
  outline: none !important;
}

.dropdown_main_1 .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  outline: none !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
  border-width: 0px !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
  border-width: 0px !important;
}

.dropdown_main_1 .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 10px;
}

.dropdown_main_1 .MuiAutocomplete-root .Mui-focused .MuiFormControl-root .MuiInputBase-root .Mui-focused {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
}

.dropdown_main_1 .MuiAutocomplete-root.Mui-focused .MuiFormControl-root .MuiInputBase-root.Mui-focused {
  border: 2px solid #3f2783 !important;
  outline: none !important;
}

.dropdown_main_1 .MuiAutocomplete-root.Mui-focused .css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
}

.dropdown_main_1 .css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 26px !important;
  background-color: #fff !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.msg-box {
  font-size: 15px;
  border: 1px solid #3f2783;
  border-radius: 8px;
  padding: 10px;
  text-align: justify;
  margin-bottom: 1rem;
}

.css-igs3ac {
  border-color: #fff !important;
  border-width: 0px !important;
}

.delete_modal_main .center_title {
  text-align: center;
  font-size: 14px;
  padding: 20px 0;
  font-weight: normal;
  margin-bottom: 0;
}

.delete_modal_main .btn_outline {
  width: 140px;
  margin-right: 15px;
}

.delete_modal_main .btn_outline {
  width: 140px;
  margin-right: 15px;
}

.delete_modal_main .modal-footer {
  justify-content: center;
}

.assm_back_btn {
  width: 212px;
  height: 62px;
  font-size: 24px;
  display: block;
  line-height: 59px;
  border-radius: 50px;
  text-align: center;
  background: transparent;
  color: #3f2783;
  outline: none;
  text-decoration: none;
  border: 2px solid #3f2783;
}

.assm_back_btn:hover {
  color: #fff;
  background: #3f2783;
}

.assm_back_btn:hover span {
  color: #fff;
}

.drop_bacground {
  padding: 5px;
  color: #000;
  font-size: 12px;
  background: transparent;
  -webkit-appearance: none;
}

.MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 28px !important;
  background-color: #fff !important;
}

.css-1l6di18 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 16px !important;
  background-color: #fff !important;
  padding-left: 20px;
}

.dropdown_main_1 .css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 28px !important;
  background-color: #fff !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 32px !important;
  background-color: #ffff !important;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 28px !important;
  background-color: #ffff !important;
}

.icn_set {
  position: absolute;
  top: 2px;
  right: 75%;
}

.sche_height input {
  height: 10px;
}

.max_width_model {
  max-width: 1200px !important;
}

.ul_bottom ul {
  border-bottom: 0px;
}

.circle_ {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #3f2783;
  display: block;
  margin-left: 13px;
  position: relative;
}

.circle_::before {
  background: #3f2783b3;
  bottom: 4px;
  content: "";
  height: 89px;
  left: .39rem;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: 0;
}


/* Responsive */

@media (min-width: 768px) {
  .modal-dialog.modal-md {
    max-width: 600px;
    margin: 30px auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-content.card_heigh {
    margin-top: 10px;
  }

  .add_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #3f2783;
    font-weight: 500;
    cursor: pointer;
    /* font-size: 9px; */
    border: 2px solid;
    border-radius: 20px;
  }

  span.add_btn img {
    width: 20px;
  }

  .circle::before {
    height: 78px;
  }

  .temp_scrolTab {
    max-height: 50vh;
    overflow: auto;
    padding-bottom: 100px;
  }
}

@media (max-width: 1450px) {
  /* .rp_100 {
    width: 100%;
  } */
}

@media only screen and (min-width: 1401px) {
  .dashboard_left_panel {
    width: 415px;
  }

  .dashboard_right_panel {
    width: calc(100% - 417px);
  }
}

@media only screen and (min-width: 1700px) {
  .dashboard_right_panel {
    width: calc(1347px - 417px);
  }
}


/* ipad screen  */
@media only screen and (max-width: 760px) {
  .pb_8 {
    padding-bottom: 8rem;
  }

  table.custom-tbl tr .col-action {
    min-width: auto;
    padding-right: 15px;
  }

  #sidebar_main.pro-sidebar {
    width: 100%;
    height: 100px !important;
    margin-top: 0;
    position: fixed;
    bottom: 0;
  }

  .content__main {
    margin-left: 0;
  }

  .pro-sidebar .pro-menu ul {
    display: flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    flex-direction: column;
    padding: 8px;
  }

  .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
    overflow-y: hidden;
    overflow-x: auto;
  }

  #sidebar_main.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 0;
  }

  .pro-sidebar-header {
    display: none;
  }

  #sidebar_main.pro-sidebar>.pro-sidebar-inner {
    background: #3f2783;
    border-radius: 0 0 0 0;
  }

  #sidebar_main.pro-sidebar.collapsed {
    width: 100%;
    min-width: 100%;
  }

  .rp_100 {
    width: 100%;
  }

  .settings_ppup_main .modal-dialog.modal-lg {
    max-width: 96%;
  }
}

@media only screen and (min-width: 1200px) {
  table.custom-tbl tr:hover .action_large_dev {
    display: block;
  }

  .settings_ppup_main .modal-dialog.modal-lg {
    max-width: 1177px;
    margin-top: 106px;
    margin-bottom: 106px;
  }

  .patient_assessment_form.fixed_vertical_menu .navbar {
    position: fixed;
    top: 30px;
  }
}

@media only screen and (min-width: 1250px) {
  .content_fx_w {
    margin: 0 auto;
    width: 1236px;
  }
}

@media only screen and (max-width: 992px) {
  .grabbable {
    flex: 0 0 auto !important;
    width: 100% !important;
  }

  .circle:before {
    background: rgba(63, 39, 131, 0.7);
    bottom: 0;
    content: "";
    height: 78px;
    left: 0.39rem;
    position: absolute;
    width: 1px;
    z-index: 0;
  }

  .w-10 {
    width: 20%;
  }

  .w-90 {
    width: 80%;
  }

  .temp_scrolTab {
    max-height: 60vh;
    overflow: auto;
    padding-bottom: 100px;
  }

  .btn_fill {
    width: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-content.card_heigh {
    margin-top: 10px;
  }

  .add_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #3f2783;
    font-weight: 500;
    cursor: pointer;
    /* font-size: 9px; */
    border: 2px solid;
    border-radius: 20px;
  }

  span.add_btn img {
    width: 20px;
  }

  .circle::before {
    height: 78px;
  }

  .temp_scrolTab {
    max-height: 50vh;
    overflow: auto;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1411px) and (max-width: 1450px) {
  table.custom-tbl thead tr th {
    width: 17% !important;
  }
}

@media (max-width: 1450px) {
  /* .rp_100 {
        width: 100%;
    } */
}

@media only screen and (min-width: 1401px) {
  .dashboard_left_panel {
    width: 415px;
  }

  .dashboard_right_panel {
    width: calc(100% - 417px);
  }
}

@media only screen and (min-width: 1700px) {
  .dashboard_right_panel {
    width: calc(1347px - 417px);
  }
}

/* @media only screen and (max-width: 1199px) {
  .pb_8 {
    padding-bottom: 8rem;
  }

  table.custom-tbl tr .col-action {
    min-width: auto;
    padding-right: 15px;
  }

  #sidebar_main.pro-sidebar {
    width: 100%;
    height: 100px !important;
    margin-top: 0;
    position: fixed;
    bottom: 0;
  }

  .content__main {
    margin-left: 0;
  }

  .pro-sidebar .pro-menu ul {
    display: flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    flex-direction: column;
    padding: 8px;
  }

  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: hidden;
    overflow-x: auto;
  }

  #sidebar_main.pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    margin-right: 0;
  }

  .pro-sidebar-header {
    display: none;
  }

  #sidebar_main.pro-sidebar > .pro-sidebar-inner {
    background: #3f2783;
    border-radius: 0 0 0 0;
  }

  #sidebar_main.pro-sidebar.collapsed {
    width: 100%;
    min-width: 100%;
  }

  .rp_100 {
    width: 100%;
  }

  .settings_ppup_main .modal-dialog.modal-lg {
    max-width: 96%;
  }
} */

@media only screen and (min-width: 1200px) {
  table.custom-tbl tr:hover .action_large_dev {
    display: block;
  }

  .settings_ppup_main .modal-dialog.modal-lg {
    max-width: 1177px;
    margin-top: 106px;
    margin-bottom: 106px;
  }

  .patient_assessment_form.fixed_vertical_menu .navbar {
    position: fixed;
    top: 30px;
  }
}

@media only screen and (min-width: 1250px) {
  .content_fx_w {
    margin: 0 auto;
    width: 1236px;
  }
}

@media only screen and (max-width: 992px) {
  .grabbable {
    flex: 0 0 auto !important;
    width: 100% !important;
  }

  .circle:before {
    background: rgba(63, 39, 131, 0.7);
    bottom: 0;
    content: "";
    height: 78px;
    left: 0.39rem;
    position: absolute;
    width: 1px;
    z-index: 0;
  }

  .w-10 {
    width: 20%;
  }

  .w-90 {
    width: 80%;
  }

  .temp_scrolTab {
    max-height: 60vh;
    overflow: auto;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) {
  .nav_inner_search .search-box {
    width: 400px;
  }

  .nav_inner_search .search-box .input-group {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1480px) {
  .rp_100 {
    width: 100%;
  }
}

@media only screen and (max-width: 1550px) {
  .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: none;
    margin-bottom: -24px;
    padding: 12px;
  }

  .pathology_page .img_box img {
    width: 100%;
    height: 100%;
  }

  .add_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #3f2783;
    font-weight: 500;
    cursor: pointer;
    /* font-size: 9px; */
    border: 2px solid;
    border-radius: 20px;
  }
}

.radio-button-custom {
  margin-top: 15px;
  margin-left: 10px;
}

.w_125 {
  max-width: 125px;
}

.join_button {
  width: 170px !important;
}

.rsa-input-placeholder {
  font-family: inherit !important;
  font-size: initial !important;
  color: #7867a8 !important;
}

.join-org .wrapper {
  font-size: 13px !important;
  font-family: inherit !important;
}

.join-org input[type="text"]::placeholder {
  color: #3f2783;
}

.join-org:hover {
  color: #fff;
}

.dropdown_main_1 {
  width: 100%;
  display: flex;
  position: relative;
}

.dropdown_main_1 input {
  padding: 0 0 0 4px !important;
  background-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  color: #3f2783;
  font-family: poppins;
}

.dropdown_main_1 input:active {
  border: none !important;
}

.dropdown_main_1 input:hover {
  border: none !important;
  background-color: #fff !important;
  outline: none !important;
}

.dropdown_main_1 .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  outline: none !important;
}

.dropdown_main_1 .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
  border-radius: 10px;
}

.dropdown_main_1 .MuiAutocomplete-root .Mui-focused .MuiFormControl-root .MuiInputBase-root .Mui-focused {
  border: 2px solid rgba(63, 39, 131, 0.7) !important;
}

.dropdown_main_1 .MuiAutocomplete-root.Mui-focused .MuiFormControl-root .MuiInputBase-root.Mui-focused {
  border: 2px solid #3f2783 !important;
  outline: none !important;
}

.dropdown_main_1 .MuiAutocomplete-root.Mui-focused .css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
}

.dropdown_main_1 .css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 26px !important;
  background-color: #fff !important;
}

.select__control {
  border-radius: 50rem !important;
}

.horizontal-line {
  height: 387px;
  width: 3px;
  background-color: #3f2783;
  /* Adjust this color to change the line's color */
}

.patient_imgS:hover {
  transform: scale(1.1);
}

.patient_imgSS:hover {
  border: 2px solid #d9d4e6;
  background-color: #cccccc36;
}

.patient_imgS {
  transition: all 0.2s ease-in;
}

.br_20 {
  border-radius: 20px !important;
}

.heart_ico {
  width: 31px;
}

.note_ptname {
  background-color: #f3f5f7;
  color: #000000;
  font-size: 14px;
  border-radius: 10px;
  text-align: center;
  padding: 7px;
  margin-left: auto;
  width: fit-content;
}

.note_ptname_icon {
  width: 30px;
  height: 30px;
  margin: 0;
  background: #5e60ce;
  border-radius: 50%;
  line-height: 28px;
  border: 2px solid #3f2783;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.modal-header.closeButton button {
  margin-bottom: 10%;
}

.add-bottom {
  background-color: #3f2783;
  color: white;
  border-radius: 5px;
  /* margin-left: 115px; */
  font-size: 26px;
  height: 24px;
  width: 24px;
  /* justify-content: center; */
  text-align: center;
  line-height: 1;
  cursor: pointer;
}

.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
  border: 2px solid rgba(63, 39, 131, 0.7);
  position: relative;
  margin-top: 0px;
  border-radius: 4px;
  height: 40px;
  padding-top: 5px;
  border-radius: 30px;
}
.css-1inkpva-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.5px 4px 7.5px 6px;
}
.MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input
{
  /* height: 2px; */
  padding: 2px 4px 7.5px 12px;
}

.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-input {
  /* height: 2px; */
  /* padding: 4px 4px 7.5px 12px; */
}

input#free-solo-3-demo {
  height: 15px;
}

.schedular_app .css-1s2u09g-control {
  border-radius: 0px !important;
}

.img_size_inc {
  display: block;
  width: auto;
}

.p_description1 {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  text-wrap: wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.p_description_pre {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  white-space: pre-wrap;
  /* Allows text to wrap */
  overflow-wrap: break-word;
  /* Ensures text breaks and wraps to the next line */
  word-wrap: break-word;
  display: inline-block;
  width: 76vw;
}

.p_description_pres {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* display: inline-block; */
  width: 76vw;
}

.p_description2 {
  font-size: 14px;
  font-family: "Poppins";
}

.add_btn1 {
  position: absolute;
  color: #3f2783;
  font-weight: 500;
  cursor: pointer;
  /* font-size: 10px; */
  border: 2px solid;
  border-radius: 34px;
  height: 28px;
  width: 28px;
}

span.add_btn1 img {
  width: 16px;
  margin-left: 4px;
  padding: 0px;
}

.modal-dialog .patient_notes_popup .modal-popup {
  width: 40vh !important;
}

/* input[type="checkbox"] {
    width: 20px;
    height: 30px;
    margin: auto;
    display: table-row;
    border: 5px solid red;
    border-radius: 50%;
   
    margin-left: 4px;
    margin-top: 1px;
} */

/* .checkbox-input {
   
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 50%;
    cursor: pointer;
    outline: none; /* Remove the default focus border in some browsers */
/* }
  
  .checkbox-input:checked {
   
    border-color: #000;
  }  */

/* new add 08/12/2023 */
/* .css-g1d714-ValueContainer {
    max-height: 114px;
    overflow-y: scroll !important;
    scrollbar-width:thin
} */
.multi_select_drp.css-b62m3t-container {
  width: 300px !important;
  word-wrap: break-word !important;
}
.add_op_multi_select_drp{
  width: 287px;
}
/* ::-webkit-scrollbar {
    width: 10px;
  }
    */

/* ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
    */

/* ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  

  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } */

.btn_disabled,
.clk_btn_disbled:disabled,
.clk_btn_disbled[disabled] {
  width: 140px;
  height: 40px;
  border: 2px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: default !important;
  border-radius: 20px;
  pointer-events: none;
}

.clk_btn_disbled:disabled:hover,
.clk_btn_disbled[disabled]:hover,
.clk_btn_disbled:disabled:focus,
.clk_btn_disbled[disabled]:focus {
  border: 2px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  outline: none !important;
  pointer-events: none;
  text-decoration: none;
}

.Linkbtn {
  border-radius: 20px;
  min-height: 32px;
  width: 86px;
  font-size: 11px;
  border: 2px solid #3f2783;
  background-color: white;
  color: #3f2783;
  font-weight: 600;
}

.LinkBtn1 {
  border-radius: 20px;
  min-height: 32px;
  width: 100px;
  font-size: 13px;
  border: 2px solid #cac8ce;
  background-color: white;
  color: #3f2783;
  font-weight: 600;
}

.promslinkbtn {
  font-weight: 600;
  font-size: 12px;
  color: orange;
}

.promsincompletebtn {
  font-weight: 600;
  font-size: 12px;
  color: skyblue;
}

.manageLink {
  border: 2px solid #3f2783;
  border-radius: 18px;
  font-size: 12px;
  padding: 3px;
}

.circle:before {
  background: rgba(63, 39, 131, 0.7);
  bottom: 0;
  content: "";
  height: 85px;
  left: 0.39rem;
  position: absolute;
  width: 1px;
  z-index: 0;
}

.model_head {
  width: 40%;
}

p.model_text {
  width: 60%;
}


.areyoulog {
  font-size: larger;
  font-style: inherit;
  font-weight: bold;
  padding-bottom: 6px;
}

.logout_modal_main .modal-dialog {
  width: auto;
}

.text-transform-none {
  text-transform: none !important;
}

.proms_Scheduler {
  padding-bottom: 12px
}

.diagicd .css-1s2u09g-control {
  margin-bottom: 2px !important;
}

.share_tab .nav-item {
  /* width: 50%; */
  width: 120%;
}

/* #case-list-tabs-tab-allcases {
  width: 100%;
} */
#case-list-tabs-tab-submitted {
  /* width: 97%; */
  width: 100%;
}

#case-list-tabs-tabpane-submitted.active.show {
  border: 2px solid #3f2783;
  padding: 15px 0;
  position: relative;
}

.share_tab .nav-tabs>li>button.active {
  border: 2px solid rgba(63, 39, 131, 0.7);
  z-index: 1;
}

.share_tab .nav-tabs>li>button.active {
  border: 2px solid rgba(63, 39, 131, 0.7);
  z-index: 1;
}

.share_tab .nav-tabs .nav-link:focus,
.share_tab .nav-tabs .nav-link:hover {
  border-color: rgba(63, 39, 131, 0.7);
}

.share_tab .tab-content,
.share_tab .tab-content>div {
  /* border-top-right-radius: 20px; */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.share_tab .tab-content {
  padding: 0 !important;
  margin-top: -1px;
  /* margin-bottom: 100px; */
}

.share_tab .nav-tabs>li>button {
  line-height: 20px;
  font-weight: 600;
  font-size: 12px;
  min-width: 91px;
  height: 44px;
  position: relative;
  margin-right: 13px;
  border-radius: 20px 20px 0 0;
  cursor: default;
  background-color: #fff;
  border: 2px solid rgba(63, 39, 131, 0.7);
  display: block;
  padding: 10px 12px;
  border-bottom: none !important;
}

div#case-list-tabs-tabpane-allcases {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 0px;
}

.SharetableContainer {
  border-radius: 20px;
  padding: 0 10px 10px 10px;
  overflow: auto;
  max-height: 45vh;
}

table.custom-tbl.consent_tbl tr .col-action {
  text-align: center;
}

.btn_gry {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #3f2783;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: white;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: 1.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.count_icon {
  position: absolute;
  text-align: center;
  right: 5px;
  background-color: #ffffff;
  color: #3f2783;
  font-weight: 700;
  border-radius: 30px;
  padding: 1px;
  min-width: 35px;
}

.count_icon_1 {
  position: absolute;
  text-align: center;
  right: 2px;
  background-color: #ffffff;
  color: #3f2783;
  font-weight: 700;
  border-radius: 30px;
  padding: 1px;
  width: 10px;
  top: 3px;
  opacity: 1.7 !important;
  height: 10px;
}

/* Table width */
table.custom-tbl.doctable thead tr th {
  width: auto;
}

table.custom-tbl.consent_tbl tr th {
  width: 35%;
}

table.custom-tbl.treatment_list_tbl tr th {
  width: 21%;
}

table.custom-tbl.event_tbl tr th {
  width: 30%;
}

table.width_op thead tr th {
  width: 13% !important;
}

table.my_clinic thead tr th {
  width: 20% !important;
}

table.doc_list_tbl thead tr th {
  width: auto !important;
}

table.seting_model thead tr th {
  width: 22% !important;

}

td.col-action.op_note_tbl_action ul {
  padding-left: 35px;
}

span.patient_list_tbl ul {
  padding-left: 1.7rem;
}

span.doc_list_tbl ul {
  padding-left: 52%;
}

.pl_10 {
  padding-left: 11% !important;
}

.pl_8 {
  padding-left: 8% !important;
}

.msg_txt {
  position: relative;
  text-align: left;
  font-size: 11px;
  font-style: italic;
  color: #3f2783;
}

.msg_txt_doc {
  position: absolute;
  text-align: left;
  font-size: 12px;
  margin-left: 23px;
  color: #3f2783;
  left: 13px;
  top: 60px;
  font-style: italic;
}

.temp_plus_icon button ::after {
  content: none !important;
}

.temp_btn_img button:after {
  display: none;
}

.active_templete {
  box-shadow: 1px 4px 5px 2px #3f278399;
  border: 2px solid #3f2783;
}

.bg_white_temp .tab-content {
  background-color: #fff;
}

.multitable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.multitableth,
.multitabletd {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.multitableth {
  background-color: #3f2783;
  color: #fff;
}

.resizeable-box {
  border: 1px solid rgb(185, 185, 185) !important;
}

.patient_list_tbl button {
  padding-left: 3rem;
}

h4.center_title_temp {
  font-size: 16px;
  font-weight: normal;
  padding: 10px 0 20px 0;
  margin-bottom: 0;
  text-align: center;
}

.btn_temp_ok {
  width: 88px;
  height: 36px;
  line-height: 33px;
}

.more_ {
  border: 1px solid;
  border-radius: 40px;
  width: -moz-fit-content;
  width: max-content;
  padding: 1px 5px;
  text-transform: capitalize;
  cursor: pointer;
  height: 16px;
  font-size: 10px;
  line-height: 12px;
}

p.step_analyse {
  color: #3f2783;
  font-weight: 500;
  border: 1px solid;
  border-radius: 30px;
  padding: 4px 0px 4px 0;
  width: 72px;
  font-size: 14px;
  text-align: center;
}

.ana_box {
  border: 1px solid #3f2783;
  border-radius: 10px;
  padding: 15px 0px 15px 12px;
}

.achecked {
  background-color: #ece9f3;
}


p.first_ana {
  color: #3f2783;
  font-size: 12px;
  font-weight: 500;

}

p.second_ana {
  color: #3f2783;
  font-size: 12px;
  font-weight: 600;
}

.ana_search {
  padding-left: 50px;
  border-radius: 10px !important;
  margin-left: -9px;
}

.ana_box_qu {
  border: 1px solid #d9d4e6;
  border-radius: 10px;
  padding: 15px 0px 0px 12px;
}

.red_dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -3px;
  background: #ff0000;
}

.blue_dot {
  background: #006dff;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -3px;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #D9D4E6;
  color: #D9D4E6;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 2s linear infinite;
  margin: auto;
}

table.custom-tbl.patient_clinic_form tbody tr td {

  /* padding: 14px 8px; */
  padding: 0px 7px;

}

.hr_margin {
  margin-bottom: -1.5rem !important
}

.custom-tbl thead {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  background: #fff;
}

.ana_box_disabled {
  border: 1px solid #9b9ea1;
  border-radius: 10px;
  padding: 15px 0px 15px 12px;
  background-color: #f3f5f7;
}
.ana_box_disabledEvent {
  border: 1px solid #9b9ea1;
  border-radius: 10px;
  padding: 15px 0px 15px 12px;
  background-color: #d2d4d6;
}
.ana_box_disabledEvent2 {
  border: 1px solid #9b9ea1;
  border-radius: 10px;
  background-color: #d2d4d6;
}

.ana_box_disabled.custom_check_box [type="checkbox"]+label:before {
  background-color: #f3f5f7;

}

.form-group.custom_check_box.tick.f_400.large.disabled :before {

  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 22px;
  height: 22px;
  border: 2px solid rgb(170 175 181);
  border-radius: 100%;
  background: #fff;
}

.blue_act {
  border: 1px solid #D9D4E6;
  border-radius: 40px;
  padding: 5px;
}

.white_act {
  border: 1px solid #D9D4E6;
  border-radius: 40px;
  padding: 5px;
  background-color: #3f2783;
}

/* Safari 10-14 */
::-webkit-input-placeholder {
  color: #fff;
}

/* Safari 15+ */
::placeholder {
  color: #fff;
}

input::-webkit-contacts-auto-fill-button {
  mask-image: "" !important;
  mask-size: 22px 12px;
  width: 0px;
  height: 0px;
  margin-left: 3px;
  margin-right: 2px;
  background-color: black;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  -webkit-user-select: none;

}

.richtextstyle {
  max-height: 526px;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 10px;
    /* Set the width of the scrollbar */
  }
}

.icd-scrollers {
  margin-right: 20px !important
}

.btn_fill_heazy {
  width: 140px;
  height: 40px;
  color: #f2f2f2;
  background: #8d889a;
  border-radius: 20px;
  border: 2px solid #f0eff2;
  display: inline-block;
  border: 2px solid #d6d6d6;
  font-size: 14px;
  padding: 0 22px;
  font-weight: 400;
  line-height: 38px;
}

.btn_fill_heazy:hover {
  background: #8d889a;
  color: #f7f7f7;
  outline: none;
  text-decoration: none;
  border: 2px solid #f6f6f6;
}


.custom_check_box_audit {
  border: 2px solid rgba(0, 0, 0, 0.2);
  /* Adjust the color and opacity for the shade */
  border-radius: 15px;
  /* Optional: add border-radius for rounded corners */
  padding: 10px 15px;
  align-items: center;
  background-color: #f8f9fa;
  /* Optional: add a background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: add a subtle shadow */
  transition: border-color 0.3s ease;
  /* Optional: transition effect for hover or focus */
}

.custom_check_box_audit:hover,
.custom_check_box:focus {
  border-color: rgba(0, 0, 0, 0.4);
  /* Darken the border on hover or focus */
}

.data_export_clear {
  border: 1px solid #3f2783;
  width: 67px;
  justify-content: center;
  display: flex;
  border-radius: 11px;
  color: #5a39b9;
}

.active {
  display: block;
  width: '25px'
}

.active-icon_for_export {
  right: 48%, ;
  top: 5px
}

.active-icon {
  left: 65px;
  top: 25px;

}

.notify-icon {
  /* border: 1px solid red; */
  width: 26px;
  margin-right: 20px;
  /* margin-left: 30px; */
  background-color: #ffffff;
}

.notify-icon_arab {
  /* border: 1px solid red; */
  width: 26px;
  margin-left: 40px;
  /* margin-left: 30px; */
  background-color: #ffffff;
}

.notification_rightPop {
  display: block;
  position: fixed;
  z-index: 1;
  top: 50px;
  right: 65px;
  color: #000;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 1s;
  background: #ffffff;
  /* border: 2px solid #583e9d22; */
  box-sizing: border-box;
  box-shadow: 0px 5px 20px 1px rgb(0 0 0 / 15%);
  border-radius: 20px;
  /* max-height: 490px; */
  width: 460px;
}

.notification_leftPop {
  display: block;
  position: fixed;
  z-index: 1;
  top: 50px;
  left: 65px;
  color: #000;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 1s;
  background: #ffffff;
  /* border: 2px solid #583e9d22; */
  box-sizing: border-box;
  box-shadow: 0px 5px 20px 1px rgb(0 0 0 / 15%);
  border-radius: 20px;
  /* max-height: 490px; */
  width: 460px;
}

.notification_container_inner {
  padding: 10px;
}

.notification_body {
  overflow-y: scroll;
  max-height: 363px;
  min-height: 100px;
  /* padding-right: 3px; */
}

.notification_body::-webkit-scrollbar {
  width: 1px;
  /* Width of the scrollbar */
}

.notification_body::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  /* Color of the scrollbar thumb */
  border-radius: 15px;
  /* Border radius of the scrollbar thumb */
}

.notification_body::-webkit-scrollbar-track {
  background-color: #ffffff;
  /* Color of the scrollbar track */
}

.notification_header {
  /* border: 1px solid green; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notification_title {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
}

.notify_btn {
  align-self: flex-end;
  display: inline-block;
  border: 1px solid #402783dc;
  padding: 2px 9px 2px 9px;
  border-radius: 15px;
  cursor: pointer;
  background-color: #ffffff;
}

.notify_item {
  border: 2px solid rgba(224, 224, 224, 0.386);
  padding: 11px 13px 11px 13px;
  border-radius: 12px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.notify_item1 {
  /* border: 0px solid #ffffff; */
  /* padding: 11px 13px 11px 13px;
  border-radius: 12px; */
  /* cursor: pointer; */
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  width: "100%"
}

.notify_item:hover {
  border: 2px solid #402783ac;
  transition: border 0.3s ease;
}

.notification_footer {
  text-align: center;
}

.notify_msg {
  color: #402783;
  font-size: 15px;
  font-weight: 600;
}

.notify_msg_unread {
  background-color: #6f40ee1e;
}

.notify_msg_read {
  background-color: #ffffff;
}

.right_icon {
  visibility: hidden;
}

.cross_icon {
  visibility: hidden;
}

.notify_item:hover .right_icon {
  visibility: visible;
}

.notify_item1:hover .cross_icon {
  visibility: visible;
}

.notify_type_icon {
  /* border: 1px solid red; */
  width: 26px;
}

.modal-right {
  position: fixed;
  right: 0;
  top: 0;
  margin: 0;
}

.blank_notification {
  border-radius: 12px;
  padding: 70px 25px 70px 25px;
}

.notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}

.notification-badge {
  position: absolute;
  top: 8px;
  right: 87px;
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 50%;
  border: 3px solid #ffffff;
  font-size: 10px;
  font-weight: 500;
  padding-top: 10px;
  width: 23px;
  height: 23px;
  text-align: center;
  z-index: 1;
}

.notification-badge-arab {
  position: absolute;
  top: -2px;
  left: 51px;
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 50%;
  border: 3px solid #ffffff;
  font-size: 13px;
  font-weight: 500;
  padding-top: 10px;
  width: 23px;
  height: 23px;
  text-align: center;
  z-index: 1;
}


.refresh-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.5s ease-in-out;
}

.rotate {
  animation: rotate-animation 1s linear infinite;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.scroll-container {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.top_18 {
  position: relative;
  top: -18px;
}

.modal-header-center-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.msg_txt_doc_share{
  color: #3f2783;
  font-size: 12px;
  font-style: italic;
  margin-top: -12px;
  margin-left: 0px;
}
.css-1hwfws3{
  padding: 11px 8px !important
}
