.table_spinner {
    text-align: center;
    position: relative;
    height: 90px;
}
.spin{
  margin: 0px auto;
}
.Ids_loader_main {
    position: absolute;
    right: 0;
    left: 0;
    top: 20px;
    bottom: 0;
}
.ids_loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #000 #000 transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .ids_loader::after,
  .ids_loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #FF3D00 #FF3D00;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .ids_loader::before {
    width: 32px;
    height: 32px;
    border-color: #000 #000 transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      